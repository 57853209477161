import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "./../../component/Header";
import OnboardingHeader from "./../../assets/animation/Entertainment.json";
import Lottie from "react-lottie";
import { Fab, IconButton } from "ui-neumorphism";
import { Column, Row, Button } from "@cred/neopop-web/lib/components";
import { useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { PiCoinsBold } from "react-icons/pi";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import coinsImg from "../../assets/images/coins.webp"

import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";


// let viewRank = [
//   {
//     Rank: 1,
//     price: 3500,
//   },
//   {
//     Rank: 2 - 10,
//     price: 1500,
//   },
//   {
//     Rank: 11 - 50,
//     price: 800,
//   },
//   {
//     Rank: 51 - 200,
//     price: 450,
//   },
//   {
//     Rank: 201 - 500,
//     price: 220,
//   },
//   {
//     Rank: 501 - 1000,
//     price: 75,
//   },
//   {
//     Rank: 1001 - 5000,
//     price: 20,
//   },
//   {
//     Rank: 1001 - 5000,
//     price: 20,
//   },
//   {
//     Rank: 1001 - 5000,
//     price: 20,
//   },
//   {
//     Rank: 1001 - 5000,
//     price: 20,
//   },
//   {
//     Rank: 1001 - 5000,
//     price: 20,
//   },
//   {
//     Rank: 1001 - 5000,
//     price: 20,
//   },
// ];



const quizRules = [
  {
    id: 1,
    description:
      "The winners for each quiz will be declared based on the score they obtain during the participation in the quiz.",
  },
  {
    id: 2,
    description:
      "There will be a fixed time for declaring the winners of each quiz.",
  },
  {
    id: 3,
    description:
      "You will have an overall 60 seconds to solve as many questions from 20 questions in the quiz.",
  },
  {
    id: 4,
    description:
      "Each question will have 4 options, and one will be the correct answer.",
  },
  {
    id: 5,
    description:
      "Each right answer will fetch you 25 points, while each wrong answer gives you -10 points.",
  },
  {
    id: 6,
    description:
      "Do not forget to use the lifelines in case you are stuck during the contest.",
  },
  {
    id: 7,
    description:
      "Users can use each lifeline once during each contest. You can use coins from your coin bank or watch an ad for a few seconds to use the lifeline for free.",
  },
  {
    id: 8,
    description: "You would have 4 different lifelines to use:",
    subItems: [
      "50:50 - On using it, two incorrect answers will be eliminated from the screen",
      "Freezer Time - A pause for the ongoing timer will take place for 30 seconds, allowing users more time to answer the question",
      "Audience Poll - You can use this option to choose the right answer out of 4 options using the intelligence of the smart audience",
      "Flip Question - A new question will replace the question currently showing on the screen",
    ],
  },
];

export default function PlayQuiz() {
  const HeadContainer = {
    loop: true,
    autoplay: true,
    animationData: OnboardingHeader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const navigate = useNavigate();

  const [popupType, setPopupType] = useState(false);
  const handlePopup = (type) => {
    setPopupType(type);
  };

  const[rank, setRank] = useState([])

  const fetchResultData = async () => {
    try {
      const response = await AXIOS?.post(
        "http://192.168.0.123/quizzeria/api/getquizdata",
        {
          device_id: "20282028",
          is_mobile: "1",
          questions_category: "3"
        });
      if (response.data.Status === 200) {
        setRank(response?.data?.ranklist)
      } else {
        console.log("error")
      }
      console.log("result", response?.data?.ranklist);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchResultData()
  }, [])

  return (
    <div className="PlayQuiz-container">
      <div className="header-main-container default-header">
        <Header />
      </div>
      <div className="Quizhead-content-container">
        <Lottie options={HeadContainer} />
      </div>
      <div className="playquiz-section">
        <div className="playQuiz-text-container">
          <h3 className="playQuiz-text-style">
            You Got 20 Seconds To Answer All Questions. Answer As Many Questions
            As You Can
          </h3>
        </div>
        <div className={`floating-button`}>
          {/* <Button
            className="join-btn"
            variant="secondary"
            kind="elevated"
            size="big"
            colorMode="dark"
            onClick={() => {
              navigate("./../Quiz");
            }}
          >
            JOIN NOW
          </Button> */}
          <button
            onClick={() => {
              navigate("./../Quiz");
            }}
          >
            JOIN NOW
          </button>
        </div>
        <div className="ghost-btn-container">
          <button className="ghost-btn hover-eff-btn">PLAY AS GHOST</button> 
          {/* <div>PLAY AS GHOST</div> */}
          {/* <Fab
            dark
            size="medium"
            bordered
            bgColor={"rgba(26, 29, 33, 1)"}
            style={{ alignItems: "center" }}
          >
           
          </Fab> */}
        </div>
        {/* <div className="sponsered-text"> */}
        <div className="sponsor-box">
          <div>
            <div className="sponsor-heading">Sponsor By</div>
          </div>
        </div>
        {/* </div> */}

        <Row className={`footer-btn playquiz-footer-section`}>
          <div className="footer-division">
            <Button
              kind="elevated"
              colorConfig={{ backgroundColor: "#1a1a1b" }}
              onClick={() => {
                setPopupType("View Rank");
              }}
            >
              <AiOutlineInfoCircle />
              &nbsp;&nbsp;View Rank
            </Button>
            <Button
              kind="elevated"
              colorConfig={{ backgroundColor: "#1a1a1b" }}
              onClick={() => {
                setPopupType("Contest Rules");
              }}
            >
              <AiOutlineInfoCircle />
              &nbsp;&nbsp;Contest Rules&nbsp;
            </Button>
          </div>
        </Row>
        <div
          className={`playquiz-footer-popup ${
            !popupType === false ? "show" : ""
          } ${popupType === "Contest Rules" ? "Contest" : ""}`}
        >
          <div className="close-btn">
            <span
              onClick={() => {
                handlePopup(false);
              }}
            >
              close
            </span>
            {/* <IconButton
              className={`popup-close-btn ${
                popupType === false ? "popup-close-btn-none" : ""
              }`}
              dark
              onClick={() => {
                handlePopup(false);
              }}
            >
              <Icon
                path={mdiClose}
                size={1}
                horizontal
                vertical
                color="#fc8417"
              />
            </IconButton> */}
          </div>
          {/* {popupType === "View Rank" ? (
              <h2>Prizes Rank List</h2>
            ) : (
              <h2>Contest Rules!</h2>
            )} */}
          {popupType === "View Rank" ? (
            <div className="BottomCard">
              <>
                <h4>Prizes Rank List</h4>
                <div className="PrizeRankListMain">
                  {rank?.map((item) => {
                    return (
                      <>
                        <div className="PrizeRankList">
                          <div>
                            <p>{item.rank}</p>
                          </div>
                          <div>
                            <p>
                              <img src={coinsImg} width={25} height={25}/>
                              {item.coins}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            </div>
          ) : null}
          {popupType === "Contest Rules" ? (
            <div className="BottomCard">
              <h4>Contest Rules!</h4>
              <ul className="contest-rules-list">
                {quizRules?.map((rule) => (
                  <li key={rule.id} className="contest-rule-item">
                    {rule?.description}
                    {rule.subItems && (
                      <ul className="sub-items-list">
                        {rule?.subItems.map((subItem, index) => (
                          <li key={index}>{subItem}</li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
