import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../../component/Header";
import png1 from "../../assets/quizzeria-img-blog/2024-01-28T190859Z_125766358_RC23R5A9AGMT_RTRMADP_3_PAKISTAN-ELECTION.jpg";
import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";


const BlogDetails = () => {

  const [data, setData] = useState([])


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await AXIOS?.post(
          "https://quzzeria.com/admin/api/getSingleBlogData",
          {
            device_id: "20282028",
            is_mobile: "1",
            blog_id: "8"
          });
        if (response.data.Status === 200) {
          setData(response.data.Data)
        } else {
          console.log("error")
        }
        console.log("blogDetails", response.data.Data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData()
  }, []);
  return (
    <div className="home-main-container">
      <div className="header-main-container blog-header">
        <Header />
      </div>
      {data?.map((row) => (
        <>
          <div className="sub-container1-BlogDetails">
            <div className="box1-BlogDetails">
              <p>
                {row?.blog_name}
              </p>
            </div>
            <div className="box2-BlogDetails">
              <img src={row?.blog_image} />
            </div>
          </div>
          <div className="sub-container2-BlogDetails">
            <div className="box3-BlogDetails">
              <p>{row?.blog_description}</p>
              {/* <p>
                eneath the cloak of pollution shrouding India’s cities, a group of
                mothers has taken it upon themselves to shield their children from
                the deeply toxic air.
              </p>
              <p>
                Calling themselves the “Warrior Moms”, the coalition of women is
                challenging thesocietal and governmental complacency that has
                allowed toxic air to become the norm.
              </p>
              <p>
                Delhi is the world’s most polluted capital city where residents
                spend four months of year shrouded in smog. In winter the Air
                Quality Index hovers around 500 – deemed 20 times higher than the
                safe limit setby the World Health Organisation, and raising the
                risks of cancer and respiratory diseases. Scientific report after
                report has found how toxic air is killing millions of Indians every
                year and shortening their lives by over a decade.
              </p>
              <p>
                For children in the capital and India’s other sprawling
                metropolises, this is particularly dangerous, leading to asthma,
                lung damage and cardiovascular illnesses. In Delhi, peak pollution
                from October to January results in schools being shut and children
                being confined indoors.
              </p>
              <p>
                Bhavreen Kandhari, 51, founder of Warrior Moms, told The Independent
                that she wants people to demand that clean air is a priority issue
                from every politician who comes to their door to ask for votes.
              </p> */}
            </div>
            <div className="box4-BlogDetails">
              <div className="sponsor-box-blogDetails-sticky">
                <div className="sponsor-box">
                  <div>
                    <div className="sponsor-heading">Advertisement</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default BlogDetails;
