import React, { useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Logo from "./../../assets/images/logo.png";
import TryOut from "./../../assets/images/Try-out-your-knowledge.png";
import OnboardingHeader from "./../../assets/animation/Onb_Hdr.json";
import QuizCategory from "./../../assets/animation/Quizcategory.json";
import dailyCoinAnimation from "./../../assets/animation/dailycoin.json";
import CountAnimation from "./../../assets/animation/playerandcounting.json";
import coinAnimation from "./../../assets/animation/shadow.json";
import Bgimage from "./../../assets/images/shape1.png";
import CoinImg from "./../../assets/images/coins_bottom.png";
import Lottie from "react-lottie";

export default function Onboarding() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("./../Entrance");
    }, 5000);
  }, [navigate]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CountAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const quizCategoryCount = {
    loop: true,
    autoplay: true,
    animationData: QuizCategory,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const HeadContainer = {
    loop: true,
    autoplay: true,
    animationData: OnboardingHeader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const dailyCoin = {
    loop: true,
    autoplay: true,
    animationData: dailyCoinAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // const playContainer = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: playAnimation,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  const coinContainer = {
    loop: true,
    autoplay: true,
    animationData: coinAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="ent-container onboarding-container">
      <div className="logo-container">
        <img src={Logo} alt="Quzzeria logo" width="150px" height="auto" />
      </div>
      <div
        className="bg-img-container"
        style={{ backgroundImage: `url(${Bgimage})` }}
      >
        <div className="head-content-container">
          <Lottie options={HeadContainer} />
        </div>
      </div>
      <div className="count-container">
        <div className="count-sub-container">
          <Lottie
            style={{ width: "65px", height: "auto" }}
            options={quizCategoryCount}
          />
          <p className="count-text-styling">Quiz Category</p>
        </div>
        <div className="count-sub-container">
          <Lottie
            style={{ width: "65px", height: "auto" }}
            options={defaultOptions}
          />
          <p className="count-text-styling">Player & Counting</p>
        </div>
        <div className="count-sub-container">
          <Lottie
            style={{ width: "65px", height: "auto" }}
            options={dailyCoin}
          />
          <p className="count-text-styling">Daily Coins</p>
        </div>
      </div>
      {/* <div className="count-text-container">
				<div className="count-text-sub-container">
					<p className="count-text-styling">Quiz Category</p>
				</div>
				<div className="count-text-sub-container">
					<p className="count-text-styling">Player & Counting</p>
				</div>
				<div className="count-text-sub-container">
					<p className="count-text-styling">Daily Coins </p>
				</div>
			</div> */}
      <div className="try-out-container">
        <img src={TryOut} alt="Quzzeria logo" />
      </div>
      {/* <div className="play-container">
        		<Lottie options={playContainer} />
  			</div>*/}
      <div className="coin-container">
        <Lottie options={coinContainer} />
        <div className="coin-img">
          <img src={CoinImg} />
        </div>
      </div>
    </div>
  );
}
