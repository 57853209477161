import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "./../../component/Header";
// import box2 from "../../assets/animation/box2.png";
import Lottie from "react-lottie";
import { Card, Fab, IconButton } from "ui-neumorphism";
import { useLocation, useNavigate } from "react-router-dom";
import { PiCoinsBold } from "react-icons/pi";
import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";


export default function PlayQuiz() {
  const HeadContainer = {
    loop: true,
    autoplay: true,
    // animationData: box2,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [isFloating, setIsFloating] = useState(true);

  const toggleFloating = () => {
    setIsFloating(!isFloating);
  };

  const navigate = useNavigate();
  const { state } = useLocation();

  const categoryDiv = ({ img = "" }) => {
    return (
      <Card
        // style={{ backgroundColor: " rgba(26, 29, 33, 1)" }}
        dark
        inset
        rounded={10}
        width={"100%"}
        className="quiz-card category-div"
      // style={{backgroundImage:`url(${img})`}}
      >
        <img onClick={() => navigate("./../subCategory")} src={img} />
      </Card>
    );
  };

  const [data, setData] = useState([])
  // console.log(data)

  const fetchResultData = async () => {
    try {
      const response = await AXIOS?.post(
        "http://192.168.0.123/quizzeria/api/savequizresults",
        {

          device_id: "20282028",
          is_mobile: "1",
          user_id: "12",
          quiz_id: "1",
          total_question: "10",
          right_answer: "1",
          wrong_answer: "0"
        });
      // if (response.data.Status === 200) {
      //   setData(response.data.Data)
      // } else {
      //   console.log("error")
      // }

      if (Array.isArray(response.data.Data)) {
        setData(response.data.Data);
      } else {
        console.error("Data is not an array.");
      }
      console.log("result", response.data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [subCategoryData, setSubCategoryData] = useState([])

  const imgArray = subCategoryData.map((category) => {
    return category.category_image;
  });

  const subCategory = async () => {
    try {
      const response = await AXIOS?.post(
        "http://192.168.0.123/quizzeria/api/home",
        {
          device_id: "20282028",
          is_mobile: "1"
        });
      if (response.data.Status === 200) {
        setSubCategoryData(response.data.Data.categorydata)
      } else {
        console.log("error")
      }
      console.log("subCategory", response.data.Data.categorydata);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchResultData()
    subCategory()
  }, [])

  return (
    <div className="result-container">
      <div className="header-main-container default-header">
        <Header />
      </div>
      <div className="result-header">
        {/* <h1>TIME'S UP, WELL PLAYED!</h1> */}
        <img src={require(`../../assets/numbers/time-up-well-played.png`)} />
      </div>
      <div className="result-content-container">
        {/* <Lottie options={HeadContainer} /> */}
        <img src={require(`../../assets/numbers/score.gif`)} />

        <div className="score">
          {state?.score
            ?.toString()
            ?.split("")
            .map((val, ind) => (
              <img
                key={ind}
                src={require(`../../assets/numbers/f${val}.png`)}
                alt={`Image ${ind + 1}`}
              />
            ))}
        </div>
      </div>
      <div className="result-section">
        <div className="result-text-container">
          <h3 className="result-text-style">
            To Be Eligible Win 200 <PiCoinsBold /> Just Join Quzzeria (its
            absolutely free & safe)
          </h3>
        </div>
        <div
          className={`floating-button ${isFloating ? "floating" : "floating"}`}
          onClick={toggleFloating}
        >
          {/* <Button
            className="join-btn"
            variant="secondary"
            kind="elevated"
            size="big"
            colorMode="dark"
            onClick={() => {
              navigate("./../Quiz");
            }}
          >
            JOIN NOW
          </Button> */}
          <button
            onClick={() => {
              navigate("./../signup");
            }}
          >
            JOIN NOW
          </button>
        </div>
        <div className="ghost-btn-container">
          <div
            onClick={() => {
              navigate("./../subCategory");
            }}
          >
            PLAY AS GHOST
          </div>
          {/* <Fab
            dark
            size="medium"
            bordered
            bgColor={"rgba(26, 29, 33, 1)"}
            style={{ alignItems: "center" }}
          >
           
          </Fab> */}
        </div>
        <Card
          className="res-section"
          dark
          rounded={50}
          width={"100%"}
          style={{ backgroundColor: "#1A1A1A!important" }}
        >
          <div className="res-main-div">
            {data.map((row, index) => (
              <>
                <div key={index} className="res-top-div">
                  <div className="res-div">
                    {/* <img
                        className="social-icon"
                        src={require(`../../assets/numbers/07.png`)}
                      /> */}
                    {row?.quiz_result_total_quastion}
                  </div>
                  <p>Current Rank</p>
                </div>
                <div className="res-top-div">
                  <div className="res-div">
                    {/* <img
                   className="social-icon"
                   src={require(`../../assets/numbers/0${state?.totalQuistion}.png`)}
                 /> */}
                    {row?.quiz_result_total_quastion}
                  </div>
                  <p>Total Question</p>
                </div>
                <div className="res-top-div">
                  <div className="res-div">
                    {/* <img
                   className="social-icon"
                   src={require(`../../assets/numbers/0${state?.correctCount}.png`)}
                 /> */}
                    {row?.quiz_result_right_answer}
                  </div>
                  <p>Currect Answer</p>
                </div>
                <div className="res-top-div">
                  <div className="res-div">
                    {/* <img
                   className="social-icon"
                   src={require(`../../assets/numbers/0${state?.wrongCount}.png`)}
                 /> */}
                    {row?.quiz_result_wrong_answer}
                  </div>
                  <p>Wrong Answer</p>
                </div>
              </>

            ))}
            {/* <div className="res-top-div">
              <div className="res-div">
                <img
                  className="social-icon"
                  src={require(`../../assets/numbers/07.png`)}
                />
              </div>
              <p>Current Rank</p>
            </div>
            <div className="res-top-div">
              <div className="res-div">
                <img
                  className="social-icon"
                  src={require(`../../assets/numbers/0${state?.totalQuistion}.png`)}
                />
              </div>
              <p>Total Question</p>
            </div>
            <div className="res-top-div">
              <div className="res-div">
                <img
                  className="social-icon"
                  src={require(`../../assets/numbers/0${state?.correctCount}.png`)}
                />
              </div>
              <p>Currect Answer</p>
            </div>
            <div className="res-top-div">
              <div className="res-div">
                <img
                  className="social-icon"
                  src={require(`../../assets/numbers/0${state?.wrongCount}.png`)}
                />
              </div>
              <p>Wrong Answer</p>
            </div> */}
          </div>
        </Card>
      </div>
      <div className="category-section">
        <div className="category-heador">
          <p>PLAY MORE QUIZ</p>
        </div>
        {/* {[1, 2, 3, 4, 5].map((val) =>
          categoryDiv({ img: require(`../../assets/category/${val}.png`) })
        )} */}
        {imgArray.map((row) =>
          categoryDiv({
            img: row,
            // img:require(row?.img)
          })
        )}
      </div>
    </div>
  );
}
