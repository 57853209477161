import React, { useEffect, useLayoutEffect, useState } from "react";
import "./style.css";
import Header from "../../component/Header";
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography } from "ui-neumorphism";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";


function AllCategory(props) {
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState([])


  const imgArray = categoryData.map((category) => {
    return category.category_image;
  });

  const questionse = async () => {
    try {
      const response = await AXIOS?.post(
        "https://quzzeria.com/admin/api/home",
        {
          device_id: "20282028",
          is_mobile: "1"
        });
      if (response.data.Status === 200) {
        setCategoryData(response.data.Data.categorydata)
      } else {
        console.log("error")
      }
      console.log("sangram", response.data.Data.categorydata);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    questionse()
  }, [])

  useLayoutEffect(() => {
    console.log('useLayoutEffect: ');
    document.getElementById("top").scrollIntoView({ behavior: "smooth" });
  }, []);

  // useEffect(() => {
  //   document.documentElement.scrollTo({
  //     top: 0,
  //     left: 0,
  //   });
  //   console.log('scrollTo: ');
  // }, []);

  const categoryDiv = ({ img = "" }) => {
    return (
      <Card
        // style={{ backgroundColor: " rgba(26, 29, 33, 1)" }}
        dark
        inset
        rounded={10}
        width={"100%"}
        className="quiz-card category-div"
      // style={{backgroundImage:`url(${img})`}}
      >
        <img onClick={() => navigate("./../subCategory")} src={img} />
      </Card>
    );
  };
  return (
    <>
      <div id="top" style={{ visibility: "hidden" }}></div>
      <div className="home-main-container" id="top" >
        <div className="header-main-container default-header">
          <Header />
        </div>
        <div className="AllCategory-section">
          <div className="AllCategory-heador">
            <p>Quiz Category For You</p>
          </div>
          {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((val) =>
            categoryDiv({ img: require(`../../assets/category/${val}.png`) })
          )} */}
          {imgArray.map((row) =>
            categoryDiv({
              img: row,
              // img:require(row?.img)
            })
          )}
        </div>
        <div className="sponsor-box">
          <div>
            <div className="sponsor-heading">Sponsor By</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCategory;
