import axios from 'axios';

// Set up default config for axios
axios.defaults.baseURL = 'https://your-api-base-url.com'; // Replace with your API base URL
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor to include CORS headers
axios.interceptors.request.use(
  (config) => {
    // You can also set other headers as needed
    config.headers['Access-Control-Allow-Origin'] = '*'; // Replace with your frontend domain in production
    config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Make your Axios request
axios.get('/your-api-endpoint')
  .then(response => {
    // Handle the response
    console.log(response.data);
  })
  .catch(error => {
    // Handle errors
    console.error('Axios request error:', error);
  });

  

export const AXIOS = axios
export const API_URL = "https://192.168.0.123/quizzeria/api/";

export const HEADER_KEY = {
  headers: {
    "Header-Key": "svg-12-2021",
  },
};

export const ENTRANCE_API = API_URL + "getquizquation";
