import Demo from "../page/demo";
import Entrance from "../page/Entrance";
import Onboarding from "../page/Onboarding";
import Submit from "../page/Submit";
import Home from "../page/Home";
import Signup from "../page/Signup";
import Signin from "../page/Signin";
import PlayQuiz from "../page/PlayQuiz";
import Quiz from "../page/Quiz";
import SubCategory from "../page/SubCategory";
import AllCategory from "../page/AllCategory";
import Result from "../page/Result";
import Blog from "../page/Blog";
import BlogDetails from "../page/Blog-Details";
import { Login } from "../page/Login";

export const routes = [
  {
    path: "/Entrance",
    element: <Entrance />,
  },
  {
    path: "/Demo",
    element: <Demo />,
  },
  {
    path: "/",
    element: <Onboarding />,
  },
  {
    path: "/Submit",
    element: <Submit />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/subCategory",
    element: <SubCategory />,
  },
  {
    path: "/Signup",
    element: <Signup />,
  },
  {
    path: "/Signin",
    element: <Signin />,
  },
  {
    path: "/PlayQuiz",
    element: <PlayQuiz />,
  },
  {
    path: "/Quiz",
    element: <Quiz />,
  },
  {
    path: "/AllCategory",
    element: <AllCategory />,
  },
  {
    path: "/Result",
    element: <Result />,
  },
  {
    path: "/Blog",
    element: <Blog />,
  },
  {
    path: "/BlogDetails",
    element: <BlogDetails />,
  },
  {
    path: "/Login",
    element: <Login />,
  },
];
