import React from "react";
import "./style.css";
import LoginBackground from "./../../assets/images/login_lines.png";
import SigninHeader from "./../../assets/images/signin.png";
import Icon from "@mdi/react";
import { mdiFacebook, mdiGoogle } from "@mdi/js";
import { Row, Button } from "@cred/neopop-web/lib/components";
import CorouselComponent from "./../../component/CarouselComponent";
import Lottie from "react-lottie";
import BackAnimation from "./../../assets/gif/bg2.json";
import { Carousel } from "react-responsive-carousel";
import googleImg from "../../assets/login/gmail_b.png";
import phoneImg from "../../assets/login/phone_b.png";
import { useNavigate } from "react-router-dom";

export default function Signin() {
  const navigate= useNavigate()

  const footerData = [
    {
      text: "When you use our services or view content provided by Quzzeria, we automatically collect and store certain information in server logs. This includes: a. details of how you used our service b. to provide relevant offers based upon your interest c. internet protocol addressd. cookies that may uniquely identify your browser or your Quzzeria Account.",
    },
    {
      text: "How we use this information We use information collected from cookies and other technologies, like pixel tags etc. to improve your user experience and the overall quality of our services. We use multiple such 3rd party services like Google Analytics, etc. We may combine personal information from one service with information, including personal information, from other services to create a rich profile of you, to better your experience on Quzzeria.",
    },
  ];
  const Background = {
    loop: true,
    autoplay: true,
    animationData: BackAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="signin-container">
      <div className="header-section">
        <img
          src={LoginBackground}
          alt="Quzzeria Background"
          width="100%"
          height="auto"
          style={{ aspectRatio: 1 }}
        />
        <div className="header-inner-section">
          <img
            src={SigninHeader}
            alt="Quzzeria Signup"
            width="300px"
            height="auto"
          />
        </div>
      </div>
      <div className="signin-note-section">
        <h4
          style={{
            fontStyle: "italic",
            color: "#fff",
            fontWeight: 200,
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          "We promise we will never spam you"
        </h4>
        <h3
          style={{
            color: "#fff",
            fontWeight: 400,
            fontSize: "14px",
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          By creating an account,you agree with Quzzeria User Guidline , Tearms
          of Use & Privacy Policy.
        </h3>
      </div>
      <Row className="signin-btn">
        <Button
          variant="primary"
          kind="flat"
          size="big"
          colorConfig={{ backgroundColor: "#1a1a1b", borderColor: "#fff" }}
        >
          <div style={{ padding: "15px 25px" }}>
            <img src={googleImg} height={25} />
          </div>
        </Button>
        <Button
          variant="primary"
          kind="flat"
          size="big"
          colorConfig={{ backgroundColor: "#1a1a1b", borderColor: "#fff" }}
        
        >
          <div style={{ padding: "15px 25px" }} >
         
            <img src={phoneImg} height={35} />
          </div>
        </Button>
      
      </Row>
    
      {/* <h5
        style={{
          textAlign: "center",
          color: "#fff",
          paddingBottom: "5px",
          fontFamily: "fantasy",
        }}
      >
        Sign In Policy
      </h5>
      <div className="footer">
        <Lottie width={"100%"} height={"auto"} options={Background} />
        <CorouselComponent footerData={footerData} title={"Sign In Policy"} />
      </div>*/}
      <div className="footer-background-container">
        <div className="footer-heading">
          <h4 className="footer-heading-text">Sign In Policy</h4>
        </div>
        {/*<img src={bgFooter} alt="Quzzeria logo" width="100%" height="auto" />*/}
        <Lottie width={"102%"} height={"auto"} options={Background} />
        <div className="footer-carousel-container">
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            centerMode={10}
            showArrows={false}
            emulateTouch={true}
            // infiniteLoop={true}
          >
            {footerData.map((item, index) => {
              return (
                <div className="slide-div" key={index}>
                  <div className="slider-card-div">
                    <div className="ent-footer-text">
                      <p className="signup-footer-text-style">{item.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
