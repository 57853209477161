import React, { useState, useEffect } from "react";
import Drawer from "../../page/Drawer";
import Logo from "./../../assets/images/logo.png";
import QLogo from "./../../assets/images/coins.png";
import "./style.css";
import { Row, Column } from "@cred/neopop-web/lib/components";
import { IconButton } from "ui-neumorphism";
import Icon from "@mdi/react";
import { mdiAccount, mdiBellBadgeOutline } from "@mdi/js";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [displayIcon, setDisplayIcon] = useState(false);
  const [number, setNumber] = useState(100);
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("LoginUser"));
    setCurrentUser(user);
  }, []);

  const toggleDrawer = () => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      setIsDrawerOpen(!isDrawerOpen);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayIcon((prevDisplayIcon) => !prevDisplayIcon);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (displayIcon) {
      setNumber("");
    } else {
      setNumber(100);
    }
  }, [displayIcon]);

  return (
    <div>
      <Row className="header-main-container">
        <Column>
          <img
            // onClick={() => }
            onClick={() => {
              window.location =
                window?.location?.hostname == "localhost"
                  ? "http://localhost:3000/Home"
                  : "https://quzzeria.com/Home";
            }}
            src={Logo}
            alt="Quzzeria logo"
            width="120px"
            height="auto"
          />
        </Column>
        <Column>
          <Row className="header-info-div">
            <Column className="header-btn">
              <IconButton
                className="head-btn"
                rounded
                text={false}
                dark
                color="#fc8417"
              >
                {displayIcon ? (
                  <img
                    src={QLogo}
                    alt="Quzzeria logo"
                    width="30px"
                    height="auto"
                  />
                ) : (
                  <span style={{ fontWeight: 600, fontFamily: "sans-serif" }}>
                    {number}
                  </span>
                )}
              </IconButton>
            </Column>
            <Column className="header-btn">
              <IconButton
                className="head-btn"
                rounded
                text={false}
                dark
                color="#fc8417"
              >
                <Icon path={mdiBellBadgeOutline} size={1} />
              </IconButton>
            </Column>
            <Column className="header-btn">
              <IconButton
                onClick={
                  currentUser && Object.keys(currentUser).length > 0
                    ? () => toggleDrawer()
                    : null
                }
                // onClick={() => toggleDrawer()}
                className="head-btn"
                rounded
                text={false}
                dark
                color="#fc8417"
              >
                <Icon path={mdiAccount} size={1} />
              </IconButton>
            </Column>
            <Column>
              <Drawer isOpen={isDrawerOpen} drawerToggle={toggleDrawer} />
            </Column>
          </Row>
        </Column>
      </Row>
      <div class="hidden-div"></div>
    </div>
  );
}
