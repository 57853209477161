import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export default function Layout({ time = Date.now() }) {
  const [cls, setCls] = useState(window?.location?.pathname);
  // useEffect(() => {
  //   setCls(window?.location?.pathname);
  // }, [time]);

  return (
    <div className="body">
      <div
        className={`container ${cls
          ?.toLowerCase()
          ?.replace("/", "")}-container`}
      >
        {" "}
        <Outlet />
      </div>
    </div>
  );
}
