import React, { useEffect, useState } from "react";
import Header from "../../component/Header";
import "./style.css";
import png1 from "../../assets/quizzeria-img-blog/2024-01-28T190859Z_125766358_RC23R5A9AGMT_RTRMADP_3_PAKISTAN-ELECTION.jpg";
import png2 from "../../assets/quizzeria-img-blog/11111808.jpg";
import png3 from "../../assets/quizzeria-img-blog/2024-01-30T035017Z_1495074740_RC22S5ACWZF6_RTRMADP_3_HONGKONG-POLITICS-SECURITY.jpg";
import png4 from "../../assets/quizzeria-img-blog/240119_OTG_RamMandirTempleConsecrationV4.png";
import png5 from "../../assets/quizzeria-img-blog/China_Evergrande_47419.jpg";
import png6 from "../../assets/quizzeria-img-blog/DSC_0164.jpg";
import png7 from "../../assets/quizzeria-img-blog/GFD6Ah6a8AAoQy1.jpeg";
import png8 from "../../assets/quizzeria-img-blog/GettyImages-1008165450.jpg";
import png9 from "../../assets/quizzeria-img-blog/GettyImages-495045930.jpg";
import png10 from "../../assets/quizzeria-img-blog/SEI189205333.jpg";
import png11 from "../../assets/quizzeria-img-blog/UN_Pakistan_Foreign_Minister_17791.jpg";
import png12 from "../../assets/quizzeria-img-blog/mald.jpg";
import png13 from "../../assets/quizzeria-img-blog/victoria mp.jpg";
import { useNavigate } from "react-router-dom";
import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";


// import { useLocation } from "react-router-dom";

const BlogContent = () => {
  // const [show, setShow] = useState("");
  // const [blogData, setBlogData] = useState([])

  // const [data, setData] = useState([])
  // const [categoryData, setCategoryData] = useState([])
  // const [subCategoryData, setSubCategoryData] = useState([])
  // const [isBar1Visible, setIsBar1Visible] = useState(false);

  const [show, setShow] = useState("");
  const [blogData, setBlogData] = useState([]);
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [isBar1Visible, setIsBar1Visible] = useState(false);

  const navigate = useNavigate();
  const handleBlogDetails = () => {
    navigate("./../BlogDetails");
  };


  const handleDivClick = (row) => {

    if (show === row.blogcategory_name) {
      setIsBar1Visible(!isBar1Visible);
      setShow("")
    } else {
      setShow(row.blogcategory_name);
      setSelectedCategory(row);
      setIsBar1Visible(true);
      // if(row?.id[0] === null){
        setCategoryId(row.id); 
      // }
    }
  };

  const handleToggleBar1 = () => {
    setIsBar1Visible(!isBar1Visible);
  };

  const handleToggleBar1Click = () => {
    setIsBar1Visible(!isBar1Visible);
  };

 
  const [categoryId, setCategoryId] = useState(null);

  const fetchData = async () => {
    try {
      const response = await AXIOS?.post(
        "https://quzzeria.com/admin/api/getblogdata",
        {
          device_id: "20282028",
          is_mobile: "1",
          category_id: categoryId
        });
      if (response.data.Status === 200) {
        setData(response.data.Data)

      } else {
        console.log("error")
      }
      console.log("data", response.data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [subcategoryId, setSubcategoryId] = useState(null);
  
  // const handleSubcategoryClick = (subCategory) => {
  //   setSubcategoryId(subCategory.id); 
  // };

  const fetchCategoryData = async () => {
    try {
      const response = await AXIOS?.post(
        "https://quzzeria.com/admin/api/getblogcategory",
        {
          device_id: "20282028",
          is_mobile: "1",
          blog_id: subcategoryId
        });
      if (response.data.Status === 200) {
        setCategoryData(response.data.Data)
        setSubCategoryData(response?.data?.Data?.subcategory)
      } else {
        console.log("error")
      }
      console.log("catagorydata", response.data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    
  // if (categoryId !== null) {
    fetchData();
  // }


    fetchCategoryData();

  }, [categoryId,subcategoryId]);

  return (
    // <div className="body">
    <div className="home-main-container">
      <div className="horizontal-sponsor-box" >
        {/* <div className="sponsor-box-sticky" > */}
        <div className="sponsor-box-horizontal-blog">
          <div>
            {/* <div className="sponsor-heading">Advertisement</div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="header-main-container blog-header">
        <Header />
      </div>

      <div className="bar">
        {categoryData?.map((row) => (
          <div
            key={row?.id}
            className={`text ${show === row?.blogcategory_name ? "active" : ""}`}
            onClick={() => handleDivClick(row)}
          >
            {row?.blogcategory_name}
          </div>
        ))}
        <div className="toggle-button" onClick={handleToggleBar1Click}>
          Toggle Bar1
        </div>
      </div>

      {isBar1Visible && selectedCategory && (
        <div className={`bar1 ${show === selectedCategory.blogcategory_name ? "show-Bar1" : ""}`}>
          {selectedCategory.subcategory?.map((subCategory) => (
            <div key={subCategory.id} className="text">
              {subCategory.blogcategory_name}
            </div>
          ))}
        </div>
      )}

      <hr class="divider" />

      {data.map((row) => (
        <>
          <div className="sub-container">
            <div className="box1">
              <div className="sub-box1">
                <div className="sub-sub-box1" onClick={handleBlogDetails}>
                  <img src={row.left_top_id[0]?.blog_image} />
                  <div className="section-container">
                    <h3>{row.left_top_id[0]?.blog_name}</h3>
                    {/* <p>this is demo purpos of test1</p> */}
                    <div className="cat">category{row?.left_top_id[0]?.category_id}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="box2">
              <div className="sub-box3">
                <div className="sub-sub-box5">
                  <img src={row?.right_top_id[0]?.blog_image} />
                  <div className="section-container">
                    <h3>{row?.right_top_id[0]?.blog_name}</h3>
                    <div className="cat">category {row?.right_top_id[0]?.category_id}</div>
                  </div>
                </div>
              </div>
              <div className="sub-box4">
                {row?.right_bottom_ids?.map((item, index) => {
                  return (
                    <div className="sub-sub-box3">
                      <div className="img-div">
                        <img src={item?.blog_image} />
                      </div>
                      <div className="text-div">
                        <div className="title">{item?.blog_name}</div>
                        <div className="cat">category{item?.category_id}</div>
                      </div>
                    </div>

                  );
                })}
              </div>
            </div>
          </div>
          <div className="sub-container2">
            <div className="box3">
              <div className="sub-box2">

                {/* {row?.left_bottom_ids?.map(
                  (item, index) => {
                    {item.id !==1}
                    return (
                      <div className="sub-sub-box2">
                        <div className="img-div">
                          <img src={item.blog_image} />
                        </div>
                        <div className="cat">category</div>
                        <div className="short">This is short Title {index}</div>
                      </div>
                    );
                  }
                )} */}

                {row?.left_bottom_ids?.map((item, index) => (
                  <div className="sub-sub-box2" key={index}>
                    {item.id !== -1 ? (
                      <>
                        <div className="img-div">
                          <img src={item.blog_image} alt={`Blog Image ${index}`} />
                        </div>
                        <div className="cat">{item?.blog_name}</div>
                        <div className="short">This is short Title {item?.category_id}</div>
                      </>
                    ) : (
                      <div className="placeholder">
                        <p>Empty Cart</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

            </div>
            <div className="box4" >
              <div className="sponsor-box-sticky" >
                <div className="sponsor-box-blog">
                  <div>
                    <div className="sponsor-heading">Advertisement</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}

    </div >

    // </div>
  );
};

export default BlogContent;
