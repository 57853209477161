import React, { lazy, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { Button } from "@cred/neopop-web/lib/components";
import animation from "../../assets/animation/Coin.json";
import MarqueeAnimation from "../../assets/gif/save_water.json";
import Lottie from "react-lottie";
import EZLogo from "./../../assets/gif/ezgif.gif";
import { Card, Typography } from "ui-neumorphism";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import FooterBg from "./../../assets/images/box1.png";
import FooterAnimation from "../../assets/gif/bg2.json";
import quzzerCoin from "../../assets/images/quzzer-coins.png";
import Marquee from "react-fast-marquee";
import { PiTreeFill } from "react-icons/pi";
import { GiWaterDrop } from "react-icons/gi";
import { FaLeaf } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";


export default function Submit() {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const BackAnimation = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const FooterBGAnimation = {
    loop: true,
    autoplay: true,
    animationData: FooterAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const footerData = [
    { text: "Get coins on the completion of each quiz." },
    { text: "Upgrade your skills with our top quizzes." },
    {
      text: "We have many top trending categories like Cricket, World, India,Business and many more!",
    },
    { text: " Millions of quiz admirer like you showed trust on us." },
    { text: "Challenge lakhs of players from across the world!" },
  ];
  return (
    <div className="head-aniamtion-container">
      <div className="back-animation">
        <Lottie
          options={BackAnimation}
          width={"100%"}
          style={{ aspectRatio: 1 }}
        />
        <div className="EZ-container">
          {<img src={EZLogo} alt="Quzzeria logo" width="100%" height="auto" />}
        </div>
        {/* <div className={`play-btn ${isHovered ? "hovered" : ""}`}>
          <button
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              navigate("./../Home");
            }}
          >
            Play Now{" "}
            <div class="shadow"></div>
          </button>
        </div> */}
        <div className={`floating-button`}>
          {/* <Button
            className="join-btn"
            variant="secondary"
            kind="elevated"
            size="big"
            colorMode="dark"
            onClick={() => {
              navigate("./../Quiz");
            }}
          >
            JOIN NOW
          </Button> */}
          <button
            onClick={() => {
              navigate("./../Home");
            }}
          >
            JOIN NOW
          </button>
        </div>
      </div>
      <div className="header-container">
        <Card
          dark
          rounded={50}
          width={"100%"}
          style={{ backgroundColor: " rgba(26, 29, 33, 1)" }}
          className="ent-card"
        >
          <div className="ent-card-box">
            <Typography
              component="h1"
              style={{ color: "white", fontSize: "18px" }}
            >
              HURRAY! YOU HAVE GOT 200
            </Typography>
            {/* <Typography
              component="h1"
              style={{
                color: "#fff",
                textAlign: "center",
                fontSize: "30px",
                fontWeight: 700,
              }}
            >
              QUIZZER COINS
            </Typography> */}
            <img src={quzzerCoin} style={{ width: "250px" }} />
          </div>
          <div className="desc">
            <Typography
              component="subtitle2"
              style={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: 400,
                textAlign: "center",
              }}
            >
              CHECK OUT MORE QUIZZES TO TEST YOUR SKILLS & KEEPS GRABING MORE
              COINS!
            </Typography>
          </div>
        </Card>
      </div>
      <div className="sponsor-box">
        <div>
          <div className="sponsor-heading">Advertisement</div>
        </div>
      </div>
      <div className="footer-container">
        <div className="footer-heading">
          <h4 className="footer-heading-text">
            Test Your Knowledge Instuntly.
          </h4>
        </div>
        <div className="footer-notes">
          <Lottie width={"107%"} height={"auto"} options={FooterBGAnimation} />
          <div className="footer-carousel-container">
            <Carousel
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              centerMode={10}
              showArrows={false}
              emulateTouch={true}
            >
              {footerData.map((item, index) => {
                return (
                  <div className="slide-div" key={index}>
                    {/* <img
                    src={FooterBg}
                    alt="Quzzerialogo"
                    width="100%"
                    height="auto"
                    // style={{ aspectRatio: 1 }}
                  /> */}
                    <div className="slider-card-div">
                      <div className="ent-footer-text">
                        <p className="ent-footer-text-style">{item.text}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div
          className="marquee"
        >
          <Lottie
            width={"102%"}
            height={"auto"}
            options={
              {
                loop: true,
                autoplay: true,
                animationData: MarqueeAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }
            }
          />
        </div>
        {/* <Marquee
          className="marquee"
          autofill={true}
          behavior="scroll"
          direction="left"
          scrollamount="2"
        >
          <span>
            <GiWaterDrop className="waterSVG" />
            SAVE WATER
          </span>
          <span>
            <RxDotFilled className="dotSVG" />
          </span>
          <span>
            <PiTreeFill className="treeSVG" /> SAVE TREE
          </span>
          <span>
            <RxDotFilled className="dotSVG" />
          </span>
          <span>
            <FaLeaf className="treeSVG" />
            SAVE SOIL
          </span>
          <span>
            <RxDotFilled className="dotSVG" />
          </span>
        </Marquee> */}
      </div>
    </div>
  );
}
