import { Routes, Route } from "react-router-dom";
import Layout from "./component/Layout";
import { routes } from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Routes>
        <Route element={<Layout time={Date.now()} />}>
          {routes?.map(({ path, element }) => (
            <Route path={path} element={element} />
          ))}
        </Route>
      </Routes>
    </>
  );
}

export default App;
