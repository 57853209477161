import React, { useState, useEffect, lazy, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { Button, Card, Fab, IconButton } from "ui-neumorphism";
import LogoHeader from "../../component/LogoHeader";

import PlayWin from "./../../../src/assets/images/PlayWin.png";
import flagImg from "./../../../src/assets/images/flag.png";
import auOffImg from "./../../../src/assets/images/audio_off.png";
import auOnImg from "./../../../src/assets/images/audio_on.png";
import fiftyImg from "../../../src/assets/images/50_50.png";
import audiImg from "../../../src/assets/images/aaudience_poll.png";
import freezeImg from "../../../src/assets/images/freeze_timer.png";
import flipImg from "../../../src/assets/images/flip_que.png";
import audiencepoll_2 from "../../../src/assets/quiz-img/audiencepoll_2.png";
import img_50_50_2 from "../../../src/assets/quiz-img/50_50_2.png";
import freeze_2 from "../../../src/assets/quiz-img/freeze_2.png";
import flip_2 from "../../../src/assets/quiz-img/flip_2.png";
import song from "../../../src/assets/audio/quizeria-Audio.mp3";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { Column, Row } from "@cred/neopop-web/lib/components";
import BottomSheet from "../../component/BottomSheet";
import { Icon } from "@iconify/react";
import { mdiClose } from "@mdi/js";

import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";

const Quiz = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [correctCount, setCorrectCount] = useState(0);
  const [wrongCount, setWrongCount] = useState(0);
  const [answeredData, setAnsweredData] = useState([""]);
  const [audioStatus, changeAudioStatus] = useState(true);
  const [questions, setQuestions] = useState([])
  const [lifelines, setLifelines] = useState([])

  const audio = useRef(new Audio(song)).current;
  const startAudio = () => {
    if (audio) {
      audio.play().catch((error) => {
        console.error("Audio play error: ", error);
      });
      changeAudioStatus(true);
    }
  };
  const pauseAudio = () => {
    if (audio) {
      audio.pause();
      changeAudioStatus(false);
    }
  };

  useEffect(() => {
    audio.load();
    startAudio();
    return () => {
      audio.pause();
    };
  }, []);

  // const questions = [
  //   {
  //     question: "Q.1). The Battle of Plassey was fought in",
  //     options: ["1757", "1782", "1748", "1764"],
  //     correctAnswer: "1757",
  //   },
  //   {
  //     question:
  //       "Q.2). The territory of Porus who offered strong resistance to Alexander was situated between the rivers of",
  //     options: [
  //       "Sutlej and Beas",
  //       "Ravi and Chenab",
  //       "Jhelum and Chenab",
  //       "Ganga and Yamuna",
  //     ],
  //     correctAnswer: "Jhelum and Chenab",
  //   },
  //   {
  //     question: "Q.3). Under Akbar, the Mir Bakshi was required to look after",
  //     options: [
  //       "state treasury",
  //       "royal household",
  //       "land revenue",
  //       "military affairs",
  //     ],
  //     correctAnswer: "military affairs",
  //   },
  //   {
  //     question: "Q.4). Tripitakas are sacred books of",
  //     options: ["Hindus", "Buddhists", "Jains", "None of the above"],
  //     correctAnswer: "Buddhists",
  //   },
  //   {
  //     question:
  //       "Q.5). The trident-shaped symbol of Buddhism does not represent",
  //     options: ["Sangha", "Buddha", "Nirvana", "Dhamma"],
  //     correctAnswer: "Nirvana",
  //   },
  //   // Add more questions here
  // ];

  
// const lifelines = [
//   { id: 1, name: "Audience Poll", img: audiImg },
//   { id: 2, name: "50:50", img: fiftyImg },
//   { id: 3, name: "Freeze Time", img: freezeImg },
//   { id: 4, name: "Flip Question", img: flipImg },
// ];

  const questionse = async () => {
    try {
      const response = await AXIOS?.post(
        "https://quzzeria.com/admin/api/getquizdata",
        {
          device_id: "20282028",
          is_mobile: "1",
          questions_category: "3"
        });
      if (response.data.Status === 200) {
        setQuestions(response.data.Data)
        setLifelines(response.data.timeline)
        setCurrentQuestion(0);
      } else {
        console.log("error")
      }
      console.log("satya", response.data.timeline);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    questionse()
  }, [])




  const [lifeLineBox, setLifeLineBox] = useState(false);
  const [frizeTime, setFrizeTime] = useState(false);
  const [popupType, setPopupType] = useState(false);
  const [selectedLifeLine, setSelectedLifeLine] = useState([]);
  const [reportQuiz, setReportQuiz] = useState();

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        if (!frizeTime) setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      navigate("./../Result", {
        state: {
          correctCount: correctCount,
          wrongCount: wrongCount,
          score: score,
          totalQuistion: questions?.length,
        },
      });
    }
  }, [timeLeft, frizeTime]);

  const handleOptionClick = (index, val) => {
    setSelectedOption(val);
    let tempScore = 0;
    let tempCorrectCount = correctCount;
    let tempWrongCount = wrongCount;
    if (!answeredData?.includes(`${currentQuestion}`)) {
      answeredData.push(`${currentQuestion}`);
      setAnsweredData([...answeredData]);
      questions[currentQuestion]?.answer == val
        ? (tempScore = score + 100)
        : (tempScore = score - 100);
      setScore(tempScore);
      questions[currentQuestion]?.answer == val
        ? ++tempCorrectCount
        : ++tempWrongCount;
      setCorrectCount(tempCorrectCount);
      setWrongCount(tempWrongCount);
    }
    setTimeout(() => {
      if (currentQuestion + 1 < questions.length) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        navigate("./../Result", {
          state: {
            correctCount: tempCorrectCount,
            wrongCount: tempWrongCount,
            score: tempScore,
            totalQuistion: questions?.length,
          },
        });
      }
    }, 2000);
  };

  const calculateTimerDashoffset = () => {
    const radius = 35; // Radius of the timer circle
    const circumference = 2 * Math.PI * radius;
    const percentage = ((60 - timeLeft) / 360) * 100;
    const offset = circumference - (circumference * percentage) / 100;
    return offset;
  };

  const handleClickIcon = (type) => {
    setPopupType(type);
  };
  const handleSelectedLifeline = (selected) => {
    setSelectedLifeLine([...selectedLifeLine, selected]);
  };

  const [isFlagPopupOpen, setFlagPopupOpen] = useState(false);
  const openflagPopup = () => {
    setFlagPopupOpen(true);
  };

  const closeFlagPopup = () => {
    setFlagPopupOpen(false);
    setReportQuiz("")
  };
  return (
    <div>
      <Popup
        open={isFlagPopupOpen}
        closeOnDocumentClick
        onClose={closeFlagPopup}
        contentStyle={{ padding: "20px" }}
      >
        {(close) => (
          <div className="Popop-main">
            <div className="header">
              <h3>Report this quiz</h3>
            </div>
            <div className="Popop-question">
              <h4>What's Wrong With This Quiz?</h4>
              <form>
                <label>
                  <input type="radio" name="flagReason" onChange={() => setReportQuiz("option1")} value="option1" />
                  it contais inappropriat content
                </label>
                <br />
                <label>
                  <input type="radio" name="flagReason" onChange={() => setReportQuiz("option2")} value="option2" />
                  it contais factually inncorrect content
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    onChange={() => setReportQuiz("other")}
                    name="flagReason"
                    value="other"
                  />
                  other
                </label>
                <br />
              </form>
              {reportQuiz == "other" ? (
                <div className="additionalDetails">
                  <h4>Please provide additional details</h4>
                  <input
                    type="text"
                    name="additionalDetails"
                    placeholder="Etner Details"
                  />
                </div>
              ) : null}
            </div>
            <button type="submit" className="submit" onClick={closeFlagPopup}>
              Submit
            </button>
            <IconButton className="closeButton" dark onClick={close}>
              <Icon
                path={mdiClose}
                size={1}
                horizontal
                vertical
                color="#fc8417"
              />
              <span style={{ color: "yellow" }}>x</span>
            </IconButton>
          </div>
        )}
      </Popup>
      <div className="quiz-module" style={{ opacity: lifeLineBox ? '0.2' : '1', pointerEvents: lifeLineBox ? 'none' : 'all' }}>
        <LogoHeader />
        <div className="quiz-play-win-img">
          <img src={PlayWin} alt="Quzzeria logo" width="300px" height="auto" />
        </div>
        <Card dark rounded={50} width={"100%"} className="quiz-card" style={{}}>
          <div className="top-icons">
            <div>
              <img className="flagImg" onClick={openflagPopup} src={flagImg} />
            </div>
            <div>
              {audioStatus ? (
                <img onClick={pauseAudio} src={auOnImg} />
              ) : (
                <img onClick={startAudio} src={auOffImg} />
              )}
            </div>
          </div>
          <div className="quiz-card-box">
            <Row className="quiz-cart-row-container">
              <Column className="timer">
                <svg className="timer-svg" viewBox="0 0 80 80">
                  <circle
                    className="timer-circle-bg"
                    cx="40"
                    cy="40"
                    r="35"
                  ></circle>
                  <circle
                    className="timer-circle"
                    cx="40"
                    cy="40"
                    r="35"
                    style={{
                      strokeDashoffset: calculateTimerDashoffset(),
                      animationPlayState: frizeTime ? "paused" : "running",
                    }}
                  ></circle>
                </svg>
                <span className="quiz-count-timer">{timeLeft} </span>
                <span className="quiz-count-second">SECOND</span>
              </Column>
              <Column>
                <div className="red-border-circle count-circle">
                  {wrongCount}
                </div>
              </Column>
              <Column>
                <div className="green-border-circle count-circle">
                  {correctCount}
                </div>
              </Column>
            </Row>
            <div className="question-count">
              <div>{`Question ${currentQuestion + 1}/${questions?.length
                }`}</div>
            </div>
            <Card
              dark
              rounded
              elevation={1}
              inset
              bordered
              className="question"
            >
              {questions[currentQuestion]?.question}
            </Card>
            <div className="options">
              {["option1", "option2", "option3", "option4"].map((optionkey, index) => {
                const option = questions[currentQuestion]?.[optionkey]
                const isSelected = selectedOption === option;
                const isCorrect =
                  option === questions[currentQuestion]?.answer;
                const buttonClassName = `quiz-option-button ${isSelected ? "selected" : ""
                  } ${isSelected && !isCorrect
                    ? "shake"
                    : selectedOption === option
                      ? "right"
                      : ""
                  }`;
                return (
                  <Button
                    key={index}
                    // bordered
                    // depressed
                    dark
                    // bgColor={buttonColor}
                    color="#fff"
                    size="large"
                    className={buttonClassName}
                    disabled={answeredData?.includes(currentQuestion)}
                    onClick={() => handleOptionClick(index, option)}
                  >
                    {option}
                  </Button>
                );
              })}
            </div>
            <Card dark rounded inset className="score-container">
              Score : {score}
            </Card>
          </div>
        </Card>
        {/* <div>
          <BottomSheet isOpen={isOpen} onClose={handleClose}>
            <h2>Bottom Sheet Content</h2>
            <p>This is the content of the bottom sheet.</p>
          </BottomSheet>
          <button className="open-button" onClick={handleOpen}>
            {isOpen ? "Close Bottom Sheet(1)" : "Open Bottom Sheet(1)"}
          </button>
          <button className="open-button_1" onClick={handleOpen}>
            {isOpen ? "Close Bottom Sheet(2)" : "Open Bottom Sheet(2)"}
          </button>
        </div> */}
      </div>
      <div className="lifeline-section">
        {/* onClick={(() => handleClickIcon(0), setLifeLineBox(!lifeLineBox))} */}
        <div
          className="lifeline-heading-div"
          onClick={() => setLifeLineBox(!lifeLineBox)}
        >
          <div>
            {!lifeLineBox ? (
              <h5>Tap to used lifeline</h5>
            ) : (
              <h5>Use lifeline</h5>
            )}
          </div>
        </div>
        <div className={`lifeline-options-div ${lifeLineBox ? "show" : ""}`}>
          {lifeLineBox && (

            <div className="lifeline-div">
              {lifelines.map((row) => {
                return (
                  <div>
                    <div
                      className={`lifeline-icon ${selectedLifeLine.includes("Audience Poll") ? "small" : ""
                        } `}
                    >
                      <img
                        src={row?.img}
                        alt={row?.name}
                        // src={audiImg}
                        // onClick={() => {
                        //   handleClickIcon(1);
                        //   // setLifeLineDtailsBox(!lifeLineDtailsBox);
                        // }}
                        onClick={() => {
                          handleClickIcon(row.id);
                          if (row.name === "Freeze Time") {
                            setFrizeTime(!frizeTime);
                          } else if (row.name === "Flip Question") {
                            if (currentQuestion >= questions?.length - 1) {
                              alert("There are no more questions available");
                              return;
                            } else {
                              setCurrentQuestion(currentQuestion + 1);
                            }
                          }
                        }}
                      />
                    </div>
                    <div>{row?.name}</div>
                  </div>
                )
              })}


              {/* <div>
                <div
                  className={`lifeline-icon ${selectedLifeLine.includes("Audience Poll") ? "small" : ""
                    } `}
                >
                  <img
                    // src={row?.img}
                    // alt={row?.name}
                    src={audiImg}
                    onClick={() => {
                      handleClickIcon(1);
                      // setLifeLineDtailsBox(!lifeLineDtailsBox);
                    }}
                  />
                </div>
                <div>Audience Poll</div>
              </div>
              <div>
                <div
                  className={`lifeline-icon ${selectedLifeLine.includes("50:50") ? "small" : ""
                    } `}
                >
                  <img
                    // alt={row?.name}
                    src={fiftyImg}
                    onClick={() => {
                      handleClickIcon(2);
                      // setLifeLineDtailsBox(!lifeLineDtailsBox);
                    }}
                  />
                </div>
                <div>50:50</div>
              </div>
              <div>
                <div
                  className={`lifeline-icon ${selectedLifeLine.includes("Time") ? "small" : ""
                    } `}
                >
                  <img
                    // alt={row?.name}
                    src={freezeImg}
                    onClick={() => {
                      handleClickIcon(3);
                      setFrizeTime(!frizeTime);
                    }}
                  />
                </div>
                <div>{frizeTime ? "UnFreeze" : "Freeze"} Time</div>
              </div>
              <div>
                <div
                  className={`lifeline-icon ${selectedLifeLine.includes("Flip Question") ? "small" : ""
                    } `}
                >
                  <img
                    src={flipImg}
                    onClick={() => {
                      handleClickIcon(4);
                      if (currentQuestion >= questions?.length - 1) {
                        alert("there is no questions available");
                        return;
                      } else {
                        setCurrentQuestion(currentQuestion + 1);
                      }
                    }}
                  />
                </div>
                <div>Flip Question</div>
              </div> */}
            </div>
      
          )}
        </div>
      </div>

      <div
        className={`lifeline-details-section ${!popupType === false ? "show" : ""
          }`}
      >
        <div className="close-btn">
          <span
            onClick={() => {
              handleClickIcon(false);
            }}
          >
            close
          </span>
          {/* <IconButton
            className={`details-close-btn ${
              popupType === false ? "details-close-btn-none" : ""
            }`}
            dark
            onClick={() => {
              handleClickIcon(false);
            }}
          >
            <Icon
              path={mdiClose}
              size={1}
              horizontal
              vertical
              color="#fc8417"
            />
          </IconButton> */}
        </div>

        {popupType === 1 ? (
          <>
            <div className="details-img">
              <img src={audiencepoll_2} />
            </div>
            <p>
              The audience poll be paused for 30 seconds to allow more time to
              answer the question
            </p>
            <div className="details-btns">
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
              >
                use for free <br />
                (watch Ads)
              </Button>
              <p>or</p>
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
                onClick={() => {
                  handleSelectedLifeline("Audience Poll");
                }}
              >
                use for 20
              </Button>
            </div>
          </>
        ) : null}

        {popupType === 2 ? (
          <>
            <div className="details-img">
              <img src={img_50_50_2} />
            </div>
            <p>
              The 50-50 will be paused for 30 seconds to allow more time to
              answer the question
            </p>
            <div className="details-btns">
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
              >
                use for free <br />
                (watch Ads)
              </Button>
              <p>or</p>
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
                onClick={() => {
                  handleSelectedLifeline("50:50");
                }}
              >
                use for 20
              </Button>
            </div>
          </>
        ) : null}

        {popupType === 3 ? (
          <>
            <div className="details-img">
              <img src={freeze_2} />
            </div>
            <p>
              The freeze timer be paused for 30 seconds to allow more time to
              answer the question
            </p>
            <div className="details-btns">
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
              >
                use for free <br />
                (watch Ads)
              </Button>
              <p>or</p>
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
                onClick={() => {
                  handleSelectedLifeline("Time");
                }}
              >
                use for 20
              </Button>
            </div>
          </>
        ) : null}

        {popupType === 4 ? (
          <>
            <div className="details-img">
              <img src={flip_2} />
            </div>
            <p>
              The flip the question be paused for 30 seconds to allow more time
              to answer the question
            </p>
            <div className="details-btns">
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
              >
                use for free <br />
                (watch Ads)
              </Button>
              <p>or</p>
              <Button
                dark
                color="#fff"
                size="large"
                className="details-main-btns"
                onClick={() => {
                  handleSelectedLifeline("Flip Question");
                }}
              >
                use for 20
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Quiz;
