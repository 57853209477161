import React from "react";
import Logo from "./../../assets/images/logo.png";

export default function LogoHeader() {
  return (
    <div className="Logo_header_container">
      <img src={Logo} alt="Quzzeria logo" width="240px" height="auto" />
    </div>
  );
}
