import React, { useEffect, useState } from 'react';
import "./style.css";
import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


export const Login = () => {
  // const [mode, setMode] = useState('mobile'); // 'mobile' or 'otp'
  // const [mobile, setMobile] = useState('');
  // const [otp, setOtp] = useState('');
  // const [mobileError, setMobileError] = useState('');
  // const [number, setNumber] = useState([])


  // const handleMobileChange = (e) => {
  //     const inputMobile = e.target.value;
  //     setMobile(inputMobile)
  //     // Validate input to ensure it contains only digits and has exactly 10 digits
  //     if (/^\d{10}$/.test(inputMobile)) {
  //         setMobileError(''); // Clear the error if the mobile number is valid
  //     } else {
  //         setMobileError('Invalid mobile number');
  //     }
  // };

  // const handleOtpChange = (e) => {
  //     setOtp(e.target.value);
  // };

  // const handleSubmit = () => {
  //     // Handle form submission logic here
  //     console.log('Mobile:', mobile);
  //     console.log('OTP:', otp);

  //     // Switch to OTP mode upon login
  //     if (/^\d{10}$/.test(mobile)) {
  //         switchToOtpMode();
  //     }
  // };

  // // const handleSubmit = async () => {
  // //     console.log('Mobile:', mobile);
  // //     console.log('OTP:', otp);
  // //     // Validate the mobile number before making the first API call
  // //     if (/^\d{10}$/.test(mobile)) {
  // //         try {
  // //             // First API call to get the number and trigger OTP
  // //             const response = await AXIOS?.post(
  // //                 "http://192.168.0.123/quizzeria/api/login",
  // //                 {
  // //                     device_id: "158296347",
  // //                     is_mobile: "1",
  // //                     // usermobile: "8523569514",
  // //                     usermobile: mobile,
  // //                 }
  // //             );

  // //             if (response.data.Status === 200) {
  // //                 // Set the number and switch to OTP mode upon successful first API call
  // //                 setNumber(response?.data?.Data?.id);
  // //                 switchToOtpMode();

  // //                 // Simulate OTP verification (replace this with your actual OTP verification logic)
  // //                 // const enteredOTP = "585331"; // Replace with the OTP entered by the user
  // //                 const enteredOTP = otp
  // //                 const verifyResponse = await AXIOS?.post(
  // //                     "http://192.168.0.123/quizzeria/api/verifyotp",
  // //                     {
  // //                         device_id: "01b9ce44c1a07b7f",
  // //                         is_mobile: "1",
  // //                         user_mobile: "8523569514",
  // //                         user_otp: "106999"
  // //                     }
  // //                 );

  // //                 if (verifyResponse.data.Status === 200) {
  // //                     console.log("OTP Verified Successfully!");
  // //                     // Additional logic to handle successful OTP verification
  // //                 } else {
  // //                     console.log("Error:", verifyResponse.data.Message);
  // //                     // Additional logic to handle OTP verification failure
  // //                 }
  // //             } else {
  // //                 console.log("Error:", response.data.Message);
  // //             }
  // //             console.log('API Response:', response);
  // //         } catch (error) {
  // //             console.error("Error fetching data:", error);
  // //         }
  // //     }
  // // };

  // // const handleSubmit = async () => {
  // //     // Validate the mobile number before making the API call
  // //     if (/^\d{10}$/.test(mobile)) {
  // //       try {
  // //         const response = await AXIOS?.post(
  // //           "http://192.168.0.123/quizzeria/api/login",
  // //           {
  // //             device_id: "158296347",
  // //             is_mobile: "1",
  // //             usermobile: "8523569514",
  // //           }
  // //         );
  // //         if (response.data.Status === 200) {
  // //           setNumber(response?.data?.Data?.id);
  // //           // Switch to OTP mode upon successful API call
  // //           switchToOtpMode();
  // //         } else {
  // //           console.log("Error:", response.data.Message);
  // //         }
  // //       } catch (error) {
  // //         console.error("Error fetching data:", error);
  // //       }
  // //     }
  // //   };

  // // const handleSubmit = async () => {
  // //     // Validate the mobile number before making the first API call
  // //     if (/^\d{10}$/.test(mobile)) {
  // //         try {
  // //             // First API call to get the number and trigger OTP
  // //             const response = await AXIOS?.post(
  // //                 "http://192.168.0.123/quizzeria/api/login",
  // //                 {
  // //                     device_id: "158296347",
  // //                     is_mobile: "1",
  // //                     usermobile: "8523569514",
  // //                 }
  // //             );
  // //             console.log(response)

  // //             if (response.data.Status === 200) {
  // //                 // Set the number and switch to OTP mode upon successful first API call
  // //                 setNumber(response?.data?.Data?.id);
  // //                 switchToOtpMode();

  // //                 // Simulate OTP verification (replace this with your actual OTP verification logic)
  // //                 // const enteredOTP = "585331"; // Replace with the OTP entered by the user
  // //                 const enteredOTP = otp
  // //                 const verifyResponse = await AXIOS?.post(
  // //                     "http://192.168.0.123/quizzeria/api/verifyotp",
  // //                     {
  // //                         // user_otp: enteredOTP,
  // //                         // user_otp: response?.data?.Data?.id
  // //                         user_otp: enteredOTP,   
  // //                     }
  // //                 );

  // //                 if (verifyResponse.data.Status === 200) {
  // //                     console.log("OTP Verified Successfully!");
  // //                     // Additional logic to handle successful OTP verification
  // //                 } else {
  // //                     console.log("Error:", verifyResponse.data.Message);
  // //                     // Additional logic to handle OTP verification failure
  // //                 }
  // //             } else {
  // //                 console.log("Error:", response.data.Message);
  // //             }
  // //         } catch (error) {
  // //             console.error("Error fetching data:", error);
  // //         }
  // //     }
  // // };

  // const switchToOtpMode = () => {
  //     setMode('otp');
  //     setMobileError(''); // Clear the error when switching to OTP mode
  // };

  // const switchToMobileMode = () => {
  //     setMode('mobile');
  //     setMobileError(''); // Clear the error when switching to mobile mode
  // };


  // const fetchdata = async () => {
  //     try {
  //       const response = await AXIOS?.post(
  //         "http://192.168.0.123/quizzeria/api/login",
  //         {
  //             device_id:"158296347",
  //             is_mobile:"1",
  //             usermobile: "8523569514",
  //         });
  //       if(response.data.Status === 200){
  //         setNumber(response?.data?.Data?.id)
  //       }else{
  //         console.log("error")
  //       }
  //       console.log("response",response?.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   useEffect(() => {
  //     fetchdata()
  //   }, [])

  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber') || '');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [userId, setUserId] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState('');

  
  const validatePhoneNumber = (number) => {
    if (/^\d{10}$/.test(number)) {
      setPhoneNumberError('');
      return true;
    } else {
      setPhoneNumberError('Invalid phone number');
      return false;
    }
  };

  const sendOtp = async () => {
   if(validatePhoneNumber(phoneNumber)){
    try {
      const response = await AXIOS?.post('https://quzzeria.com/admin/api/login', {
        device_id: "158296347",
        is_mobile: "1",
        usermobile: phoneNumber
      });

      if (response.data.Status === 200) {
        setOtpSent(true);
        alert('OTP Sent Successfully!');
        console.log(response)
        localStorage.setItem('phoneNumber', phoneNumber);

      } else {
        alert('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
   }
  };

  const navigate = useNavigate();

  const verifyOtp = async () => {
    try {
      const response = await AXIOS?.post('https://quzzeria.com/admin/api/verifyotp', {
        device_id: "158296347",
        is_mobile: "1",
        user_mobile: phoneNumber,
        user_otp: otp
      });
      console.log('ss L:', response?.data)
      if (response.data.Status === 200) {
        alert('Login Successful!');
        navigate("/Home")
      } else {

        alert('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div className="drawer-login-user">
      <div className="head-section">
        <img src={require("./../../assets/login/user.png")} />
      </div>
      {/* <div> */}
      <label className='text'>Mobile Number</label>
      <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
      <div className="text2">{phoneNumberError}</div>

      <button className='button' onClick={sendOtp}>Send OTP</button>
      {/* </div> */}

      {otpSent && (
        <>
          <label className='text'>OTP</label>
          <input type="text" value={otp} onChange={(e) => setOtp(e.target.value)} />
          <button className='button' onClick={verifyOtp}>Verify OTP</button>
        </>
      )}
    </div>

  )
}