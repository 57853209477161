import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../../component/Header";
import { useNavigate } from "react-router-dom";
import { Button, Card, Divider, Typography } from "ui-neumorphism";
import { Row, Column } from "@cred/neopop-web/lib/components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Live from "./../../assets/animation/live.json";
import Reward from "./../../assets/animation/reward.json";
import Video from "./../../assets/animation/coin2.json";
import Lottie from "react-lottie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faInfo, faPlus } from "@fortawesome/free-solid-svg-icons";
import Congratulations from "./../../assets/gif/congratulation.json";
import coinWin from "../../assets/images/coins-win.webp";
import coinLost from "../../assets/images/coins-lost.webp";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fab, IconButton } from "ui-neumorphism";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";


export default function Home() {
  const navigate = useNavigate();

  const handleAllCategory = () => {
    navigate("./../AllCategory");
  };

  // const handleBlog = () => {
  //   navigate("./../Blog");
  // };

  const CongraulationAnimation = {
    loop: true,
    autoplay: true,
    animationData: Congratulations,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [allQuizData, setAllQuizData] = useState([
    {
      label: "18 again",
      img: 1,
    },
    {
      label: "ashneer grover",
      img: 2,
    },
    {
      label: "automobiles",
      img: 3,
    },
    {
      label: "banking",
      img: 4,
    },
    {
      label: "bhagat singh",
      img: 5,
    },
    {
      label: "birds/animals",
      img: 6,
    },
    {
      label: "ms dhoni",
      img: 7,
    },
    {
      label: "narendra modi",
      img: 8,
    },
    {
      label: "shah rukh khan",
      img: 9,
    },
  ]);
  const [activeQuizData, setActiveQuizData] = useState([
    {
      label: "18 again",
      img: 1,
    },
    {
      label: "ashneer grover",
      img: 2,
    },
  ]);
  const [quizVisible, setQuizVisible] = useState(false);
  const [selectedQuizData, setSelectedQuizData] = useState([]);
  console.log("selectedQuizData: ", selectedQuizData);
  console.log("selectedQuizDatalenght: ", selectedQuizData?.length);

  const liveAnimation = {
    loop: true,
    autoplay: true,
    animationData: Live,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const rewardAnimation = {
    loop: true,
    autoplay: true,
    animationData: Reward,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const videoAnimation = {
    loop: true,
    autoplay: true,
    animationData: Video,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const categoryDiv = ({ img = "" }) => {
    return (
      <Card
        // style={{ backgroundColor: " rgba(26, 29, 33, 1)" }}
        dark
        inset
        rounded={10}
        width={"100%"}
        className="quiz-card category-div"
      // style={{backgroundImage:`url(${img})`}}
      >
        <img onClick={() => navigate("./../subCategory")} src={img} />
      </Card>
    );
  };

  const [quizStart, setQuizStart] = useState(false);
  const [quizComplete, setQuizComplete] = useState(false);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [score, setScore] = useState(0);
  const [questions, setQuestions] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [miniQuizSubCategory, setMiniQuizSubCategory] = useState([])
  const [subMiniQuizSubCategory, setSubMiniQuizSubCategory] = useState([])

  // const questions = [
  //   {
  //     question: "What is the capital of France?",
  //     options: ["Paris", "London", "Berlin", "Tokyo"],
  //     correctAnswer: "Paris",
  //   },
  //   {
  //     question: "What is the largest planet in our solar system?",
  //     options: ["Earth", "Jupiter", "Mars", "Moon"],
  //     correctAnswer: "Jupiter",
  //   },
  //   {
  //     question: "Who is current prime minister of india?",
  //     options: [
  //       "Arvind Kejriwal",
  //       "Indira Gandhi",
  //       "Rahul Gandi",
  //       "Narendra Modi",
  //     ],
  //     correctAnswer: "Narendra Modi",
  //   },
  // ];

  const imgArray = categoryData.map((category) => {
    return category.category_image;
  });

  const questionse = async () => {
    try {
      const response = await AXIOS?.post(
        "https://quzzeria.com/admin/api/home",
        {
          device_id: "20282028",
          is_mobile: "1"
        });
      if (response.data.Status === 200) {
        setQuestions(response.data.Data.miniquizdata)
        setCategoryData(response.data.Data.categorydata)
        setMiniQuizSubCategory(response.data.Data.miniquizcategorydata)
        setSubMiniQuizSubCategory(response.data.Data.miniquizcategorydata)
        setCurrentQuestion(0);
      } else {
        console.log("error")
      }
      console.log("sangram", response.data.Data.miniquizcategorydata);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    questionse()
  }, [])

  const handleNextQuestion = (option) => {
    setSelectedOption(option);
    setScore(option !== questions[currentQuestion].correctAnswer ? 1 : score);

    if (currentQuestion === questions.length - 1) {
      setTimeout(() => {
        setSelectedOption("");
        setQuizComplete(true);
      }, 1000);
    } else {
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOption("");
      }, 1000);
    }
  };
  const [reportQuiz, setReportQuiz] = useState();
  const [isFlagPopupOpen, setFlagPopupOpen] = useState(false);
  const openflagPopup = () => {
    setFlagPopupOpen(true);
  };

  const closeFlagPopup = () => {
    setFlagPopupOpen(false);
    setReportQuiz("");
  };

  return (
    <>
      <Popup
        open={isFlagPopupOpen}
        closeOnDocumentClick
        onClose={closeFlagPopup}
        contentStyle={{ padding: "20px" }}
      >
        {(close) => (
          <div className="Popop-main">
            <div className="header">
              <h3>Report this quiz</h3>
            </div>
            <div className="Popop-question">
              <h4>What's Wrong With This Quiz?</h4>
              <form>
                <label>
                  <input
                    type="radio"
                    name="flagReason"
                    onChange={() => setReportQuiz("option1")}
                    value="option1"
                  />
                  it contais inappropriat content
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    name="flagReason"
                    onChange={() => setReportQuiz("option2")}
                    value="option2"
                  />
                  it contais factually inncorrect content
                </label>
                <br />
                <label>
                  <input
                    type="radio"
                    onChange={() => setReportQuiz("other")}
                    name="flagReason"
                    value="other"
                  />
                  other
                </label>
                <br />
              </form>
              {reportQuiz == "other" ? (
                <div className="additionalDetails">
                  <h4>Please provide additional details</h4>
                  <input
                    type="text"
                    name="additionalDetails"
                    placeholder="Etner Details"
                  />
                </div>
              ) : null}
            </div>
            <button type="submit" className="submit" onClick={closeFlagPopup}>
              Submit
            </button>
            <IconButton className="closeButton" dark onClick={close}>
              <Icon
                path={mdiClose}
                size={1}
                horizontal
                vertical
                color="#fc8417"
              />
            </IconButton>
          </div>
        )}
      </Popup>
      <div className="home-main-container">
        <div className="header-main-container default-header">
          <Header />
        </div>
        {quizStart ? (
          <div className={`home-quiz ${quizStart ? "show" : ""}`}>
            <Card
              dark
              rounded={50}
              width={"100%"}
              style={{ backgroundColor: "rgba(26, 29, 33, 1)" }}
              className="ent-card"
            >
              {quizComplete ? (
                <div className="complete-box">
                  <div className="head-section">
                    <div className="heading">
                      <h3
                        // style={{fontSize:"16px !important"}}

                        onClick={() => {
                          setScore(0);
                          setQuizStart(false);
                          setQuizComplete(false);
                          setSelectedOption("");
                          setCurrentQuestion(0);
                        }}
                      // className="miniQuiz"
                      >
                        Mini Quiz
                      </h3>
                    </div>
                  </div>
                  <div className="box">
                    <h3>{score > 0 ? "Oops" : "Congratulations"}</h3>
                    <div className="img">
                      <Lottie
                        width={"80%"}
                        height={"auto"}
                        style={{ margin: "-69px" }}
                        options={CongraulationAnimation}
                      />
                    </div>
                    <small>
                      {score > 0
                        ? "Better Luck Next Time"
                        : "You just got 30 coins!"}
                    </small>
                  </div>
                  <div class="bottom">
                    <button
                      className="parent-quiz-button"
                      onClick={() => {
                        setQuizStart(false);
                        setScore(0);
                        setQuizComplete(false);
                        setSelectedOption("");
                        setCurrentQuestion(0);
                        setSelectedQuizData([]);
                        navigate("../../Home");
                      }}
                    >
                      Retry
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="ent-card-box">
                    <Typography
                      component="h2"
                      style={{
                        color: "white",
                        fontSize: "22px",
                        fontWeight: 700,
                      }}
                      className="d-none"
                    >
                      quiz bites
                    </Typography>
                    <div className="count">
                      {/* Question {currentQuestion + 1}/{questions?.length} */}
                      Mini Quiz
                    </div>
                  </div>
                  <hr className="d-none" style={{ width: "100%" }} />
                  <div className="question">
                    <Typography component="subtitle" className="subtitle">
                      {questions[currentQuestion].question}
                    </Typography>

                    <div className="question-options">
                      {["option1", "option2", "option3", "option4"].map((optionKey, index) => {
                        const option = questions[currentQuestion]?.[optionKey]
                        const isSelected = selectedOption === option;
                        const isCorrect =
                          option === questions[currentQuestion]?.answer;
                        const buttonClassName = `option-button ${isSelected ? "selected" : ""
                          } ${isSelected && !isCorrect
                            ? "shake"
                            : selectedOption === option
                              ? "right"
                              : ""
                          }`;

                        return (
                          <Button
                            key={index}
                            bordered
                            dark
                            // bgColor={buttonColor}
                            color="#fff"
                            size="large"
                            className={buttonClassName}
                            onClick={() => handleNextQuestion(option)}
                          >
                            {option}
                          </Button>
                        );
                      }
                      )}
                    </div>
                  </div>
                  <div className="ent-card-box">
                    <div className="quiz-count">
                      Question {currentQuestion + 1}/{questions?.length}
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        ) : (
          <div className="parent-quiz d-flex gap-1">
            <div className="quiz-main-div">
              {quizVisible ? (
                <div
                  className={`heading ${quizVisible ? "center" : ""}`}
                  style={{ padding: "5px 30px!important" }}
                >
                  <h3>Pick Your topics for Quiz Bites</h3>
                </div>
              ) : (
                <div className="head-section">
                  <div className="icon-left">
                    <FontAwesomeIcon
                      className="edit"
                      icon={faInfo}
                      onClick={() => openflagPopup()}
                    />
                  </div>
                  <div className="heading">
                    <h3>Mini Quiz</h3>
                  </div>
                  <div className="icon-right">
                    <FontAwesomeIcon
                      className="edit"
                      icon={faEdit}
                      onClick={() => setQuizVisible(!quizVisible)}
                    />
                  </div>
                </div>
              )}
              {/*  style={{marginBottom:'10px'}} */}
              <div className="quiz-section">
                {miniQuizSubCategory?.map((row, index) => {
                  return activeQuizData[index]?.label?.length > 0 ? (
                    <div
                      className={`quiz-box ${selectedQuizData.includes(row?.category_name)
                          ? "QuizDataBorder"
                          : ""
                        }`}
                      onClick={() =>
                        setSelectedQuizData([
                          ...selectedQuizData,
                          row?.category_name,
                        ])
                      }
                    >
                      {/* <div
                      className={`icon ${quizVisible ? " " : "d-none"} d-none`}
                      onClick={() => {
                        activeQuizData?.splice(
                          activeQuizData?.map((row) => row?.img)?.indexOf(val),
                          1
                        );
                        setActiveQuizData([...activeQuizData]);
                      }}
                    >
                      X
                    </div> */}
                      <div class={`img-div ${quizVisible ? "show" : ""}`}>
                        <div className="img">
                          <img
                            // src={require(`../../assets/images/${activeQuizData[index]?.img}.webp`)}
                            src={row?.category_image} alt={`Quiz Image ${index}`}
                          />
                          {/* {val?.category_image} */}
                        </div>
                        <div className="name">
                          {/* {activeQuizData[index]?.label} */}
                          {row?.category_name}
                        </div>
                      </div>
                      {/* <div
                      className={`button ${quizVisible ? " " : "d-none"}`}
                      onClick={() => {
                        activeQuizData?.splice(
                          activeQuizData?.map((row) => row?.img)?.indexOf(val),
                          1
                        );
                        setActiveQuizData([...activeQuizData]);
                      }}  
                    >
                      <span>remove</span>
                    </div> */}
                    </div>
                  ) : (
                    <div
                      className="quiz-box plus"
                      onClick={() => {
                        if (activeQuizData?.length > 0) {
                          setQuizVisible(true);
                        }
                      }}
                    >
                      <div className="plus-div">
                        <FontAwesomeIcon
                          icon={faPlus}
                          color="#fe8d2c"
                          size="lg"
                        />
                      </div>
                      {/* <div className={`button ${quizVisible ? " " : "d-none"}`}>
                      <span>add</span>
                    </div> */}
                    </div>
                  );
                })}
              </div>
              <ToastContainer />
              <div class="bottom">
                <button
                  className="parent-quiz-button hover-eff-btn"
                  onClick={() => {
                    if (quizVisible) {
                      if (activeQuizData?.length <= 0) {
                        // alert("Minimum 1 Quiz Category Required");
                        toast.warning("Minimum 1 Quiz Category Required", {
                          position: toast.POSITION.BOTTOM_CENTER,
                          theme: "dark",
                        });
                        return false;
                      }
                      setQuizVisible(false);
                    } else {
                      setQuizStart(true);
                      // if (selectedQuizData?.length <= 0) {
                      //   alert("Minimum 1 Quiz Required");
                      // } else {
                      //   setQuizStart(true);
                      // }
                    }
                    // activeQuizData?.length > 0 ? navigate("../../Entrance") : alert('Add Quiz');
                  }}
                >
                  {quizVisible ? "Confirm Selection" : "Start Quiz"}
                </button>
              </div>
            </div>
            <div className={`bottom-division ${quizVisible ? "open" : " "}`}>
              <div className="quiz-section bottom-section">
                {subMiniQuizSubCategory?.map((value,index) => {
                  return (
                    <div
                      className={`quiz-box ${miniQuizSubCategory
                          ?.map((row) => row?.category_image)
                          ?.includes(value?.category_image)
                          ? ""
                          : "plus"
                        }`}
                    >
                      <div
                        className="icon d-none"
                        onClick={() => {
                          if (
                            miniQuizSubCategory
                              ?.map((row) => row?.category_image)
                              ?.indexOf(value?.category_image) >= 0
                          ) {
                            miniQuizSubCategory?.splice(
                              miniQuizSubCategory
                                ?.map((row) => row?.category_image)
                                ?.indexOf(value?.category_image),
                              1
                            );
                          } else {
                            if (miniQuizSubCategory?.length > 2) {
                              miniQuizSubCategory[2] = value;
                            } else {
                              miniQuizSubCategory.push(value);
                            }
                          }
                          setMiniQuizSubCategory([...miniQuizSubCategory]);
                        }}
                      >
                        {miniQuizSubCategory
                          ?.map((row) => row?.category_image)
                          ?.includes(value?.category_image)
                          ? "X"
                          : "+"}
                      </div>
                      <div class={`img-div ${quizVisible ? "show" : ""}`}>
                        <div className="img">
                          <img
                            // src={require(`../../assets/images/${value?.img}.webp`)}
                            src={value?.category_image} alt={`Quiz Image ${index}`}
                          />
                        </div>
                        <div className="name">{value?.category_name}</div>
                      </div>
                      <div
                        className={`button hover-eff-btn ${quizVisible ? " " : "d-none"
                          }`}
                        onClick={() => {
                          if (
                            miniQuizSubCategory
                              ?.map((row) => row?.category_image)
                              ?.indexOf(value?.category_image) >= 0
                          ) {
                            miniQuizSubCategory?.splice(
                              miniQuizSubCategory
                                ?.map((row) => row?.category_image)
                                ?.indexOf(value?.category_image),
                              1
                            );
                          } else {
                            if (miniQuizSubCategory?.length > 2) {
                              miniQuizSubCategory[2] = value;
                            } else {
                              miniQuizSubCategory.push(value);
                            }
                          }
                          setMiniQuizSubCategory([...miniQuizSubCategory]);
                        }}
                      >
                        <span>
                          {miniQuizSubCategory
                            ?.map((row) => row?.category_image)
                            ?.includes(value?.category_image)
                            ? "remove"
                            : "add"}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="sponsor-box">
              <div>
                <div className="sponsor-heading">Sponsor By</div>
              </div>
            </div>
            {!quizVisible ? <Divider elevated className={"divider"} /> : <></>}
            <div className={`bottom-division ${quizVisible ? "open" : " "}`}>
              <div className="quiz-section bottom-section">
                {miniQuizSubCategory?.reverse()?.map((value, index) => {
                  return (
                    <div
                      className={`quiz-box ${miniQuizSubCategory
                          ?.map((row) => row?.category_image)
                          ?.includes(value?.category_image)
                          ? ""
                          : "plus"
                        }`}
                    >
                      <div
                        className="icon d-none"
                        onClick={() => {
                          if (
                            miniQuizSubCategory
                              ?.map((row) => row?.category_image)
                              ?.indexOf(value?.category_image) >= 0
                          ) {
                            miniQuizSubCategory?.splice(
                              miniQuizSubCategory
                                ?.map((row) => row?.category_image)
                                ?.indexOf(value?.category_image),
                              1
                            );
                          } else {
                            if (miniQuizSubCategory?.length > 2) {
                              miniQuizSubCategory[2] = value;
                            } else {
                              miniQuizSubCategory.push(value);
                            }
                          }
                          setMiniQuizSubCategory([...miniQuizSubCategory]);
                        }}
                      >
                        {miniQuizSubCategory
                          ?.map((row) => row?.category_image)
                          ?.includes(value?.category_image)
                          ? "X"
                          : "+"}
                      </div>
                      <div class={`img-div ${quizVisible ? "show" : ""}`}>
                        <div className="img">
                          <img
                            // src={require(`../../assets/images/${value?.img}.webp`)}
                            src={value?.category_image} alt={`Quiz Image ${index}`}
                          />
                        </div>
                        <div className="name">{value?.category_name}</div>
                      </div>
                      <div
                        className={`button ${quizVisible ? " " : "d-none"}`}
                        onClick={() => {
                          if (
                            miniQuizSubCategory
                              ?.map((row) => row?.category_image)
                              ?.indexOf(value?.category_image) >= 0
                          ) {
                            miniQuizSubCategory?.splice(
                              miniQuizSubCategory
                                ?.map((row) => row?.category_image)
                                ?.indexOf(value?.category_image),
                              1
                            );
                          } else {
                            if (miniQuizSubCategory?.length > 2) {
                              miniQuizSubCategory[2] = value;
                            } else {
                              miniQuizSubCategory.push(value);
                            }
                          }
                          setMiniQuizSubCategory([...miniQuizSubCategory]);
                        }}
                      >
                        <span>
                          {miniQuizSubCategory
                            ?.map((row) => row?.category_image)
                            ?.includes(value?.category_image)
                            ? "remove"
                            : "add"}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <div></div>
        {!quizVisible ? (
          <>
            <div className="category-section">
              <div className="category-heador">
                <p>Quiz Category For You</p>
                <button onClick={handleAllCategory}>
                  <span>See All</span>
                  <span>
                    <IoIosArrowDroprightCircle />
                  </span>
                </button>
              </div>
              {/* {[1,2,3,4,5].map((val) =>
                categoryDiv({
                  img: require(`../../assets/category/${val}.png`),
                  // img:require(row?.img)
                })
              )} */}
              {imgArray.map((row) =>
                categoryDiv({
                  img: row,
                  // img:require(row?.img)
                })
              )}
            </div>
            <div className="sponsor-box">
              <div>
                <div className="sponsor-heading">Sponsor By</div>
              </div>
            </div>
            <div className="category-button">
              <button
                onClick={() => {
                  window.location =
                    window?.location?.hostname == "localhost"
                      ? "http://localhost:3000/blog"
                      : "https://quzzeria.com/blog";
                }}
              >
                View Our Blog
              </button>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
