import React from "react";
import "./style.css";
import { Button } from "ui-neumorphism";

export default function ThemeConfig() {
  const colorCodeData = [
    {
      label: "light 1",
      style: {
        bg_color: "#636161",
        bg_dark_shadow: "#7d7979",
        bg_light_shadow: "#454343",
        primary_color: "#ff1744",
        primary_dark_color: "#d50000",
        primary_light_color: "#ff8a80",
        text_color: "#dbd9d9",
      },
    },

    // f07575
    {
      label: "light 2",
      style: {
        bg_color: "#118030",
        bg_dark_shadow: "#24b34a",
        bg_light_shadow: "#10401d",
        primary_color: "#009688",
        primary_dark_color: "#00695c",
        primary_light_color: "#80cbc4",
        text_color: "#c2f2cf",
      },
    },
    {
      label: "light 3",
      style: {
        bg_color: "#7330d9",
        bg_dark_shadow: "#8b4beb",
        bg_light_shadow: "#380b7a",
        primary_color: "#9c27b0",
        primary_dark_color: "#6a1b9a",
        primary_light_color: "#595beb",
        text_color: "#d3c0f0",
      },
    },
    {
      label: "light 4",
      style: {
        bg_color: "#cccccc",
        bg_dark_shadow: "#ebe6e6",
        bg_light_shadow: "#a3a3a3",
        primary_color: "#424242",
        primary_dark_color: "#616161",
        primary_light_color: "#9e9e9e",
        text_color: "#262626",
      },
    },
  ];

  const manageTheme = (row) => {
    console.log("ss :: ", row);
    document?.documentElement?.style?.setProperty(
      "--page-bg-color",
      row?.style?.bg_color
    );
    document?.documentElement?.style?.setProperty(
      "--card-bg-color",
      row?.style?.bg_color
    );
    document?.documentElement?.style?.setProperty(
      "--bg-dark-shadow",
      row?.style?.bg_dark_shadow
    );
    document?.documentElement?.style?.setProperty(
      "--bg-light-shadow",
      row?.style?.bg_light_shadow
    );
    document?.documentElement?.style?.setProperty(
      "--btn-bg-color",
      row?.style?.bg_color
    );
    document?.documentElement?.style?.setProperty(
      "--btn-bg-dark-shadow",
      row?.style?.bg_light_shadow
    );
    document?.documentElement?.style?.setProperty(
      "--btn-bg-light-shadow",
      row?.style?.bg_dark_shadow
    );
    document?.documentElement?.style?.setProperty(
      "--txt-color",
      row?.style?.text_color
    );
  };

  return (
    <div className="theme-config-container">
      <div className="color-options">
        {colorCodeData?.map((row) => (
          <Button onClick={() => manageTheme({ ...row })}>{row?.label}</Button>
        ))}
      </div>
    </div>
  );
}
