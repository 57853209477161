import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Typography } from "ui-neumorphism";
import Logo from "./../../assets/images/logo.png";
import "./style.css";
import FooterBg from "./../../assets/images/box1.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Lottie from "react-lottie";
import BackAnimation from "./../../assets/gif/bg1.json";
export default function Demo() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [score, setScore] = useState(0);
  const navigate = useNavigate();

  const footerData = [
    { text: "Get coins on the completion of each quiz." },
    { text: "Upgrade your skills with our top quizzes." },
    {
      text: "We have many top trending categories like Cricket, World, India,Business and many more!",
    },
    { text: " Millions of quiz admirer like you showed trust on us." },
    { text: "Challenge lakhs of players from across the world!" },
  ];
  const questions = [
    {
      question: "What is the capital of France?",
      options: ["Paris", "London", "Berlin", "Tokyo"],
      correctAnswer: "Paris",
    },
    {
      question: "What is the largest planet in our solar system?",
      options: ["Earth", "Jupiter", "Mars", "Moon"],
      correctAnswer: "Jupiter",
    },
    {
      question: "Who is current prime minister of india?",
      options: [
        "Arvind Kejriwal",
        "Indira Gandhi",
        "Rahul Gandi",
        "Narendra Modi",
      ],
      correctAnswer: "Narendra Modi",
    },
    // Add more questions here
  ];
  const Background = {
    loop: true,
    autoplay: true,
    animationData: BackAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleNextQuestion = (option) => {
    setSelectedOption(option);
    if (option === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
    }

    if (currentQuestion === questions.length - 1) {
      // Reached the end of the quiz
      setTimeout(() => {
        navigate("./../Submit");
      }, 1000);
      // You can also reset the quiz here if desired
    } else {
      // Move to the next question after 3 seconds
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOption("");
      }, 1000);
    }
  };

  return (
    <div className="main-entr-container">
      <div className="logo-box">
        {/*<div style={{ zIndex: 10 }}>
          <Drawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />{" "}
          </div>*/}
        <div>
          <img src={Logo} alt="Quzzeria logo" width="120px" height="auto" />
        </div>
      </div>
      <div className="sponsor-box">
          <div>
            <div className="sponsor-heading">Sponsor By</div>
          </div>
      </div>
      <div className="ent-card-box">
        <Typography
          component="h1"
          style={{
            color: "white",
            fontSize: "25px",
            fontWeight: 400,
            fontFamily: "gilroy",
          }}
        >
          Let's Start!
        </Typography>
        <Typography
          component="subtitle"
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "15px",
            fontWeight: 300,
          }}
        >
          Just answer 3 questions and win 300 coins.
        </Typography>
      </div>
      <Card
        dark
        rounded={50}
        width={"100%"}
        style={{ backgroundColor: "#1A1A1A!important" }}
        className="ent-card"
      >
        <div className="question_count_main_card">
          <Card
            dark
            rounded={50}
            width={"100%"}
            style={{ backgroundColor: "#1A1A1A!important" }}
            className="question_count_card"
          >
            <div className="question_count">
              <Typography component="subtitle" className="subtitle">
                Question {currentQuestion + 1}/3
              </Typography>
            </div>
          </Card>
        </div>

        {currentQuestion < questions.length ? (
          <div className="question">
            <Typography component="subtitle" className="subtitle">
              {questions[currentQuestion].question}
            </Typography>

            <div className="question-options">
              {questions[currentQuestion].options.map((option, index) => {
                const isSelected = selectedOption === option;
                const isCorrect =
                  option === questions[currentQuestion].correctAnswer;
                const buttonClassName = `option-button ${
                  isSelected ? "selected" : ""
                } ${
                  isSelected && !isCorrect
                    ? "shake"
                    : selectedOption === option
                    ? "right"
                    : ""
                }`;

                return (
                  <Button
                    key={index}
                    bordered
                    dark
                    // bgColor={buttonColor}
                    // color="#fff"
                    size="large"
                    className={buttonClassName}
                    onClick={() => handleNextQuestion(option)}
                  >
                    {option}
                  </Button>
                );
              })}
            </div>
          </div>
        ) : (
          <p>No more questions.</p>
        )}

        <div className="fun-fact" style={{ display: "none" }}>
          <Typography
            component="h3"
            style={{
              color: "white",
              fontSize: "14px",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            #Fun Fact
          </Typography>
          <Typography
            component="subtitle2"
            style={{
              color: "white",
              fontSize: "12px",
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            Indian left-arm medium pacer Sohail Tanvir got the first ever Purple
            Cap award.
          </Typography>
        </div>
      </Card>
      <div className="login-div">
        <Button onClick={() => navigate("./../Signup")}>Sign Up</Button>
        <span style={{ color: "#fff" }}> or </span>
        <Button dark onClick={() => navigate("./../Signin")}>
          Sign In
        </Button>
      </div>
      <div className="footer-background-container">
        <div className="footer-heading">
          <h4 className="footer-heading-text">
            Test Your Knowledge Instuntly.
          </h4>
        </div>
        {/*<img src={bgFooter} alt="Quzzeria logo" width="100%" height="auto" />*/}
        <Lottie width={"100%"} height={"auto"} options={Background} />
        <div className="footer-carousel-container">
          <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            centerMode={10}
            showArrows={false}
            emulateTouch={true}
          >
            {footerData.map((item, index) => {
              return (
                <div
                  className="slide-div"
                  key={index}
                  style={{
                    display: "inline-flex",
                    width: "95%",
                    // padding: "21px 0",
                  }}
                >
                  <img
                    src={FooterBg}
                    alt="Quzzerialogo"
                    width="100%"
                    height="auto"
                    // style={{ aspectRatio: 1 }}
                  />
                  <div className="ent-footer-text">
                    <p className="ent-footer-text-style">{item.text}</p>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}
