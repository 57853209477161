import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../../component/Header";
import { useNavigate } from "react-router-dom";
import { Button, Card } from "ui-neumorphism";
import { Row, Column } from "@cred/neopop-web/lib/components";
import QuizBg1 from "./../../assets/quiqBgImg/1a.png";
import QuizBg2 from "./../../assets/quiqBgImg/2a.png";
import QuizBg3 from "./../../assets/quiqBgImg/3a.png";
import QuizBg4 from "./../../assets/quiqBgImg/4a.png";
import QuizBg5 from "./../../assets/quiqBgImg/5a.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Live from "./../../assets/animation/live.json";
import Reward from "./../../assets/animation/reward.json";
import Video from "./../../assets/animation/coin2.json";
import Lottie from "react-lottie";
import { AXIOS, ENTRANCE_API, HEADER_KEY } from "../../config/Contant";


export default function SubCategory() {
  const navigate = useNavigate();
  const Category = [
    { text: "ENTERTAINMENT & POP-CULTURE", id: 1 },
    { text: "GEOGRAPHY & TRAVEL", id: 2 },
    { text: "HEALTH & MEDICINE", id: 3 },
    { text: "LIFESTYLES & MEDICAL ISSUES", id: 4 },
    { text: "LITARATURE", id: 5 },
    { text: "PHILOSOPHY & RELIGION", id: 6 },
    { text: "SCIENCE", id: 7 },
    { text: "SPORTS & RESEARCH", id: 8 },
    { text: "TECHNOLOGY", id: 9 },
    { text: "VISUAL ARTS", id: 10 },
    { text: "WORLD HISTORY", id: 11 },
    { text: "POLITICS,LAW & GOVT", id: 12 },
    { text: "COADING", id: 13 },
    { text: "EMOJI", id: 14 },
    { text: "FESTIVAL", id: 15 },
    { text: "GAMING", id: 16 },
    { text: "k-POP", id: 17 },
    { text: "LOGO & ICON", id: 18 },
  ];


  const quizList = [
    QuizBg1,
    QuizBg2,
    QuizBg3,
    QuizBg4,
    QuizBg5
    // {
    //   cat_id: 1,
    //   id: 1,
    //   title: "Match the Defination of the World : 12th-Grade Vocabulary",
    //   sub_title: "Winner Announcement At 9:30 PM",
    //   video: 50,
    //   live: "150k",
    //   reward: "60k",
    //   background: QuizBg1,
    // },
    // {
    //   cat_id: 2,
    //   id: 2,
    //   title: "12th-Grade Vocabulary",
    //   sub_title: "Winner Announcement At 9:30 PM",
    //   video: 30,
    //   live: "180k",
    //   reward: "50k",
    //   background: QuizBg2,
    // },
    // {
    //   cat_id: 3,
    //   id: 3,
    //   title: "How Well Do You Know 11th Grade Vocabulary?",
    //   sub_title: "Winner Announcement At 9:30 PM",
    //   video: 85,
    //   live: "3855",
    //   reward: "120k",
    //   background: QuizBg3,
    // },
    // {
    //   cat_id: 4,
    //   id: 4,
    //   title: "11th Grade Vocabulary?",
    //   sub_title: "Winner Announcement At 9:30 PM",
    //   video: 15,
    //   live: "35k",
    //   reward: "236k",
    //   background: QuizBg4,
    // },
    // {
    //   cat_id: 5,
    //   id: 5,
    //   title: "10th Grade Vocabulary?",
    //   sub_title: "Winner Announcement At 9:30 PM",
    //   video: 38,
    //   live: "83k",
    //   reward: "356k",
    //   background: QuizBg5,
    // },
  ];

  const [categoryData, setCategoryData] = useState([])



  const categoryQuiz = async () => {
    try {
      const response = await AXIOS?.post(
        "https://quzzeria.com/admin/api/getsubcategorydata",
        {
          device_id: "20282028",
          is_mobile: "1"
        });
      if (response.data.Status === 200) {
        setCategoryData(response.data.Data)

      } else {
        console.log("error")
      }
      console.log("subcategory", response.data.Data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    categoryQuiz()
  }, [])

  const [visible, setVisible] = useState(0);
  const liveAnimation = {
    loop: true,
    autoplay: true,
    animationData: Live,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const rewardAnimation = {
    loop: true,
    autoplay: true,
    animationData: Reward,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const videoAnimation = {
    loop: true,
    autoplay: true,
    animationData: Video,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="home-main-container">
      <div className="header-main-container default-header">
        <Header />
      </div>
      {/* <hr style={{ width: "100%", marginTop: "10px" }} /> */}
      {/* <div className="button-gallery">
        {Category.map((item, index) => (
          <Button
            key={index}
            dark
            depressed
            bgColor={"rgba(26, 29, 33, 1)"}
            className="gallery-button"
            onClick={() => setVisible(item.id)}
          >
            <h2 style={{ fontSize: "10px", textAlign: "center" }}>
              {item.text}
            </h2>
          </Button>
        ))}
      </div> */}
      <div className="category-section" style={{ marginTop: "70px" }}>
        {categoryData.map((item, index) => {
          // const matchingQuiz = quizList.find((quizItem) => quizItem.cat_id === item.id);
          console.log("Index:", index);
          console.log("quizList[index]:", quizList[index]);
          return (
            <Card
              // style={{ backgroundColor: " rgba(26, 29, 33, 1)" }}
              dark
              inset
              rounded={10}
              width={"100%"}
              className="quiz-card category-div"
            >
              <div className="quiz-header-section category-top-div">
                {/* <img src={item.background} alt={`Background ${index}`} /> */}
                {/* <img src={quizList[index]} alt={`Background ${index}`} className="quiz-background-image"/> */}
                {quizList[index] && (
                <img
                  src={quizList[index]}
                  alt={`Background ${index}`}
                  onLoad={() => console.log(`Image ${index} loaded successfully`)}
                  // className="quiz-background-image"
                />
              )}

                {/* {matchingQuiz && (
                  <imgz
                    src={matchingQuiz.background}
                    alt={`Background ${index}`}
                  className="quiz-background-image"
                  />
                )} */}
                <div className="quiz-header-title">
                  <h3 className="text-style">{item?.quiz_name}</h3>
                  <h6 className="sub-text-style">{item?.quiz_winning_time}</h6>
                </div>
              </div>
              <div className="category-bottom-div">
                <div className="botton-info-div">
                  <div>
                    <div className="subCategoryCounting">
                      <Lottie
                        width={"40px"}
                        height={"40px"}
                        options={liveAnimation}
                      />
                    </div>
                    <h6 className="quiz-footer-left-text-style">{item?.quiz_live2}</h6>
                  </div>
                  <div>
                    <div className="subCategoryCounting">
                      <Lottie
                        width={"40px"}
                        height={"40px"}
                        options={rewardAnimation}
                      />
                    </div>
                    <h6 className="quiz-footer-left-text-style">{item?.quiz_reward2}</h6>
                  </div>
                  <div>
                    <div className="subCategoryCounting">
                      <Lottie
                        width={"40px"}
                        height={"40px"}
                        options={videoAnimation}
                      />
                    </div>
                    <h6 className="quiz-footer-left-text-style">{item?.quiz_coin2}</h6>
                  </div>
                </div>
                <div className="botton-btn-div">
                  <div>
                    <Button
                      className="cat-btn"
                      dark
                      depressed
                      size="medium"
                      onClick={() => navigate("./../PlayQuiz")}
                    >
                      Play
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          )

        })}
      </div>
    </div>
  );
}
