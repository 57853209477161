import React, { useEffect, useState } from "react";
import { IconButton, Card } from "ui-neumorphism";
import Logo from "./../../assets/images/logo.png";
import fbImg from "./../../assets/images/fb.png";
import igImg from "./../../assets/images/insta.png";
import ptImg from "./../../assets/images/pinterest.png";
import ttImg from "./../../assets/images/twitter.png";
import ytImg from "./../../assets/images/youtube.png";
import user from "./../../assets/login/user.png";
import { PiCoinsBold } from "react-icons/pi";
import { FaUserAlt } from "react-icons/fa";

import "./style.css";
import Icon from "@mdi/react";
import {
  mdiAccount,
  mdiArrowRight,
  mdiClose,
  mdiFacebook,
  mdiInstagram,
  mdiMenu,
  mdiTwitch,
  mdiTwitter,
  mdiYoutube,
} from "@mdi/js";
import animationGhost from "./ghost.json";
import ghostGif from "./ghost.gif";
import Lottie from "react-lottie";
import ThemeConfig from "../../component/ThemeConfig";
import { useNavigate } from "react-router-dom";
import {

  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
} from "react-share";

export default function Drawer({ isOpen, drawerToggle }) {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(isOpen);
  const [pageName, setPageName] = useState("");
  const [isIndian, setIsIndian] = useState("indian");

  const shareUrl = "http://192.168.0.123/quizzeria/api/"

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    drawerToggle(!isDrawerOpen);
  };

  useEffect(() => {
    setIsDrawerOpen(isOpen);
  }, [isOpen]);

  let viewRank = [
    {
      Name: "User1",
      price: 3500,
    },
    {
      Name: "User2",
      price: 1500,
    },
    {
      Name: "User3",
      price: 800,
    },
    {
      Name: "User4",
      price: 450,
    },
    {
      Name: "User5",
      price: 220,
    },
    {
      Name: "User6",
      price: 75,
    },
    {
      Name: "User7",
      price: 20,
    },
    {
      Name: "User8",
      price: 20,
    },
    {
      Name: "User9",
      price: 20,
    },
    {
      Name: "User10",
      price: 20,
    },
    {
      Name: "User11",
      price: 20,
    },
    {
      Name: "User12",
      price: 20,
    },
  ];

  const infoPages = {
    privacy_policy:
      "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of 'de Finibus Bonorum et Malorum' (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, 'Lorem ipsum dolor sit amet..', comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
    terms_condition:
      "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of 'de Finibus Bonorum et Malorum' (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, 'Lorem ipsum dolor sit amet..', comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
    about_us:
      "What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of 'de Finibus Bonorum et Malorum' (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, 'Lorem ipsum dolor sit amet..', comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
  };

  const handleSignIn = () => {
    navigate("./../Signin");
  };
  const handleSignUp = () => {
    navigate("./../Signup");
  };

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("LoginUser"));
    setCurrentUser(user);
  }, []);

  const handleSignOut = () => {
    localStorage.removeItem("LoginUser");
    setCurrentUser();
  };

  const handleIndian = (data) => {
    setIsIndian(data);
  };

  return (
    <div className={`drawer ${isDrawerOpen ? "open" : ""}`}>
      {!isDrawerOpen && !currentUser ? (
        <IconButton className="show-menu-btn" dark onClick={toggleDrawer}>
          <Icon
            path={mdiMenu}
            size={1}
            className=""
            horizontal
            vertical
            color="#fc8417"
          />
        </IconButton>
      ) : (
        <></>
      )}

      <div className={`open-header-menu ${isDrawerOpen ? "open" : ""}`}>
        {pageName.length > 0 ? (
          <IconButton
            className="toggle-button "
            dark
            onClick={() => {
              setPageName(false);
            }}
          >
            <Icon
              path={mdiArrowRight}
              size={1}
              horizontal
              vertical
              color="#fc8417"
            />
          </IconButton>
        ) : (
          <IconButton className="toggle-button " dark onClick={toggleDrawer}>
            <Icon
              path={mdiClose}
              size={1}
              horizontal
              vertical
              color="#fc8417"
            />
          </IconButton>
        )}
        <div className="content">
          {/* ---------------- */}

          {/* ---------------- */}

          <div className="nav-container">
          {/* {!currentUser ? ( */}
          {currentUser && Object.keys(currentUser).length > 0 ? (
           
              <>
                <div className="drawer-login-user">
                  <div className="head-section">
                    <img src={require("./../../assets/login/user.png")} />
                    {/* <FaUserAlt/> */}
                    {/* <IconButton
                      className="head-btn"
                      rounded
                      text={false}
                      dark
                      color="#fc8417"
                    >
                      <Icon path={mdiAccount} size={1} />
                    </IconButton> */}
                  </div>
                  <div className="user-detail-section">
                    <div className="user-detail">
                      <label>Name :</label>
                      <input
                        type="text"
                        readOnly
                        placeholder={currentUser.name}
                        value={currentUser.name}
                      />
                    </div>
                    <div className="user-detail">
                      <label>surname :</label>
                      <input
                        type="text"
                        readOnly
                        placeholder={currentUser.surname}
                        value={currentUser.surname}
                      />
                    </div>
                    <div className="user-detail">
                      <label>Email :</label>
                      <input
                        type="email"
                        readOnly
                        placeholder={currentUser.Email}
                        value={currentUser.Email}
                      />
                    </div>
                    <div className="user-detail">
                      <label>Gender :</label>
                      <input
                        className="user-detail-radio"
                        type="radio"
                        readOnly
                        name="gender"
                        value="male"
                        checked={currentUser.gender === "male"}
                      />
                      <label className="user-detail-label">male</label>
                      <input
                        className="user-detail-radio"
                        type="radio"
                        readOnly
                        value="female"
                        checked={currentUser.gender === "female"}
                      />
                      <label className="user-detail-label">female</label>
                    </div>
                  </div>
                  <div class="bottom">
                    <button
                      className="drawer-login-button hover-eff-btn"
                      onClick={handleSignOut}
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
                <div className={`login-user-footer`}>
                  <div className="login-user-header">
                    <h4>Today Leader Board</h4>
                  </div>
                  <div className="PrizeRankListMain">
                    {viewRank?.map((item) => {
                      return (
                        <>
                          <div className="PrizeRankList">
                            <div>
                              <p>{item.Name}</p>
                            </div>
                            <div>
                              <p>
                                <PiCoinsBold />
                                {item.price}
                              </p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div
                  class="logo-section"
                  style={{
                    marginTop: pageName.length > 0 ? "25px!important" : "35px",
                  }}
                >
                  <div class={`logo-div ${pageName?.length > 0 && 'real-logo'}`}>
                    <img src={pageName?.length > 0 ? Logo : ghostGif} />
                    {/* <Lottie
                    options={animationGhost}
                    height={50}
                    width={50}
                  /> */}
                  </div>
                </div>
                {pageName.length > 0 ? (
                  <div className="info-pages">
                    <div className="button-div">
                      {pageName != "about_us" ? (
                        <div className="btn-div">
                          <div
                            className={isIndian === "no indian" ? "Active" : ""}
                            onClick={() => handleIndian("no indian")}
                          >
                            no indian
                          </div>
                          <div
                            className={isIndian === "indian" ? "Active" : ""}
                            onClick={() => handleIndian("indian")}
                          >
                            indian
                          </div>
                        </div>
                      ) : (
                        <div className="about-btn">about us</div>
                      )}
                    </div>
                    <div className="content">
                      <p>{infoPages[pageName]}</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="user-section">
                      <label>Welcome user</label>
                    </div>
                    <div className="auth-section">
                      <div className="auth-div">
                        <button className="auth-btn hover-eff-btn" onClick={handleSignIn}>
                          sign in
                        </button>
                        <button className="auth-btn hover-eff-btn" onClick={handleSignUp}>
                          sign up
                        </button>
                      </div>
                    </div>
                    <div className="page-section">
                      <div className="page-div">
                        <button
                          className="page-btn hover-eff-btn"
                          onClick={() => {
                            setPageName("privacy_policy");
                          }}
                        >
                          privacy policy
                        </button>
                      </div>
                      <div className="page-div">
                        <button
                          className="page-btn hover-eff-btn"
                          onClick={() => {
                            setPageName("terms_condition");
                          }}
                        >
                          terms & condition
                        </button>
                      </div>
                      <div className="page-div">
                        <button
                          className="page-btn hover-eff-btn"
                          onClick={() => {
                            setPageName("about_us");
                          }}
                        >
                          about us
                        </button>
                      </div>
                      <div className="page-div">
                        <button className="page-btn hover-eff-btn">contact us</button>
                      </div>
                    </div>
                    <Card
                      className="social-section"
                      dark
                      rounded={50}
                      width={"100%"}
                      style={{ backgroundColor: "#1A1A1A!important" }}
                    >
                      <div className="social-header-div">
                        <p> Follow Us </p>
                      </div>
                      <div className="social-main-div">
                        <div className="social-div">
                          {/* <img className="social-icon" src={fbImg}  /> */}
                          <FacebookShareButton
                            url={shareUrl}
                            quote={"hello"}
                            hashtag={"#satya"}
                          >
                            {/* <FacebookIcon size={25} round={true} /> */}
                            <img className="social-icon" src={fbImg} />
                          </FacebookShareButton>
                        </div>
                        <div className="social-div">
                          <img className="social-icon" src={igImg} />
                        </div>
                        <div className="social-div">
                          {/* <img className="social-icon" src={ttImg} /> */}
                          <TwitterShareButton url={shareUrl}>
                            <img className="social-icon" src={ttImg} />
                          </TwitterShareButton>
                        </div>
                        <div className="social-div">
                          <img className="social-icon" src={ytImg} />
                        </div>
                      </div>
                    </Card>
                    <div>
                      <ThemeConfig />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
